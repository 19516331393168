import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import roundRating from '../utils/roundRating'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    color: theme.palette.text,
    width: 266,
    padding: theme.spacing(1),
  },
  emptyPhoto: {
    width: '100%',
    height: 166,
    maxHeight: 148,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 8,
  },
  photo: {
    display: 'block',
    maxWidth: '100%',
    width: '100%',
    maxHeight: 148,
    height: 'auto',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto',
    borderRadius: 8,
  },
  tags: {
    fontWeight: 400,
    fontSize: 12,
  },
  info: {
    fontSize: 14,
  },
}))

function RestaurantCard({ children, restaurant }) {
  const [isHovered, setIsHovered] = useState(false)
  const styles = useStyles()

  const tags = JSON.parse(restaurant.tags).join(' · ').toUpperCase()

  function renderFirstPhoto() {
    const pictureUrls = JSON.parse(restaurant.pictureUrls)
    const pictureUrl = pictureUrls[0]

    if (!pictureUrl) {
      return (
        <Box className={styles.emptyPhoto} />
      )
    }

    return (
      <img
        src={pictureUrl}
        className={styles.photo}
        alt="restaurant"
        style={{ backgroundImage: `url(${pictureUrl})` }}
      />
    )
  }

  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      display="flex"
      height="100%"
    >
      <Paper
        component={RouterLink}
        to={`/restaurant/${restaurant.id}`}
        elevation={isHovered ? 2 : 0}
        className={styles.root}
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
        >
          {renderFirstPhoto()}
          <Box mt={1}>
            <Typography
              noWrap
              variant="subtitle2"
              className={styles.tags}
            >
              {tags}
            </Typography>
          </Box>
          <Box display="flex">
            <Box flexGrow={1}>
              <Typography variant="h6">
                {restaurant.name}
              </Typography>
            </Box>
            <Typography variant="h6">
              {roundRating(restaurant.averageRating)}
            </Typography>
          </Box>
          <Box mt={1}>
            <Typography className={styles.info}>
              {restaurant.address}
            </Typography>
          </Box>
          {children}
        </Box>
      </Paper>
    </Box>
  )
}

export default RestaurantCard
