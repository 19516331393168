import React from 'react'

import AdminisitratorDashboardPage from './AdministratorDashboardPage'

function AdministratorDashboardUsers() {
  return (
    <AdminisitratorDashboardPage
      actionTypeSuffix="REVIEW"
      fetchUrl="/reviews"
      stateKey="reviews"
      columns={{
        qualityOfFoodRating: {
          type: 'number',
        },
        serviceRating: {
          type: 'number',
        },
        settingRating: {
          type: 'number',
        },
        comment: {
          type: 'string',
        },
        reply: {
          type: 'string',
        },
        userId: {
          type: 'number',
          doesNotUpdate: true,
        },
        restaurantId: {
          type: 'number',
          doesNotUpdate: true,
        },
      }}
    />
  )
}

export default AdministratorDashboardUsers
