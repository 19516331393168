import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'

import './index.css'

import ViewerFetcher from './components/ViewerFetcher'
import Router from './Router'

import theme from './theme'
import store from './state/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ViewerFetcher>
          <Router />
        </ViewerFetcher>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
