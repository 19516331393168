import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

function Footer({ footerAbsolute }) {
  const style = {}

  if (footerAbsolute) {
    style.position = 'absolute'
    style.bottom = 0
    style.left = 0
    style.right = 0
  }

  return (
    <Box
      my={3}
      textAlign="center"
      style={style}
    >
      <Typography>
        Gourmet - Copyright David Hérault {new Date().getFullYear()}
      </Typography>
    </Box>
  )
}

export default Footer
