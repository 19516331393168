import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import TuneOutlinedIcon from '@material-ui/icons/TuneOutlined'
import MenuIcon from '@material-ui/icons/Menu'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import ProfilePicture from './ProfilePicture'

import configuration from '../configuration'
import dispatchAction from '../state/dispatchAction'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: 'white',
    zIndex: 1000,
  },
  burger: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  logo: {
    width: 42,
    height: 42,
    [theme.breakpoints.down('md')]: {
      width: 36,
      height: 36,
    },
  },
  brand: {
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  brandSubtitle: {
    marginBottom: 1,
    color: theme.palette.grey[500],
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  menuLink: {
    textTransform: 'uppercase',
    letterSpacing: 0.8,
  },
  menuContent: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  userMenu: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}))

function NavigationBar({ viewer, isViewerLoaded }) {
  const [menuAnchorElement, setMenuAnchorElement] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const history = useHistory()
  const styles = useStyles()

  function handleSignOutClick() {
    setMenuAnchorElement(null)

    localStorage.removeItem(configuration.tokenKey)

    dispatchAction('UNSET_VIEWER')
    dispatchAction('DELETE_USER', { id: viewer.id })

    history.push('/')
  }

  function renderViewerMenu() {
    const viewerFullName = `${viewer.firstName} ${viewer.lastName}`

    return (
      <>
        <Typography>
          {viewerFullName}
        </Typography>
        <Box ml={1}>
          <IconButton
            size="small"
            onClick={event => setMenuAnchorElement(event.currentTarget)}
          >
            <ProfilePicture url={viewer.profilePictureUrl} />
          </IconButton>
          <Menu
            keepMounted
            anchorEl={menuAnchorElement}
            open={Boolean(menuAnchorElement)}
            onClose={() => setMenuAnchorElement(null)}
          >
            <MenuItem
              onClick={() => setMenuAnchorElement(null)}
              component={RouterLink}
              to={`/user/${viewer.id}`}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <PersonOutlinedIcon />
                <Box ml={2}>
                  <Typography>
                    Profile
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <MenuItem
              onClick={() => setMenuAnchorElement(null)}
              component={RouterLink}
              to="/settings"
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <SettingsOutlinedIcon />
                <Box ml={2}>
                  <Typography>
                    Settings
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            {viewer.isAdministrator && (
              [
                <Divider light key={0} />,
                <MenuItem
                  key={1}
                  onClick={() => setMenuAnchorElement(null)}
                  component={RouterLink}
                  to="/administrator-dashboard"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    <TuneOutlinedIcon />
                    <Box ml={2}>
                      <Typography>
                        Administrator dashboard
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>,
              ]
            )}
            <Divider light />
            <MenuItem
              onClick={handleSignOutClick}
            >
              <Box
                display="flex"
                alignItems="center"
              >
                <ExitToAppOutlinedIcon />
                <Box ml={2}>
                  <Typography>
                    Sign out
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </>
    )
  }

  function renderAuthenticationButtons() {
    return (
      <>
        <RouterLink to="/sign-in">
          <Button color="primary">
            Sign in
          </Button>
        </RouterLink>
        <Box ml={2}>
          <RouterLink to="/sign-up">
            <Button
              color="primary"
              variant="contained"
            >
              Sign up
            </Button>
          </RouterLink>
        </Box>
      </>
    )
  }

  function renderDrawer() {
    return (
      <SwipeableDrawer
        anchor="left"
        open={isDrawerOpen}
        onOpen={() => setIsDrawerOpen(true)}
        onClose={() => setIsDrawerOpen(false)}
      >
        <List>
          <ListItem
            button
            component={RouterLink}
            to="/restaurants"
          >
            <ListItemIcon>
              <ExploreOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Explore" />
          </ListItem>
          {!!viewer && (
            <>
              <ListItem
                button
                component={RouterLink}
                to={`/user/${viewer.id}`}
              >
                <ListItemIcon>
                  <PersonOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
              <ListItem
                button
                component={RouterLink}
                to="/settings"
              >
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItem>
              <Divider />
              <ListItem
                button
                onClick={handleSignOutClick}
              >
                <ListItemIcon>
                  <ExitToAppOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign out" />
              </ListItem>
            </>
          )}
          {!viewer && (
            <>
              <ListItem
                button
                component={RouterLink}
                to="sign-in"
              >
                <ListItemIcon>
                  <LockOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign in" />
              </ListItem>
              <ListItem
                button
                component={RouterLink}
                to="/sign-up"
              >
                <ListItemIcon>
                  <PersonOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Sign up" />
              </ListItem>
            </>
          )}
        </List>
      </SwipeableDrawer>
    )
  }

  return (
    <>
      {renderDrawer()}
      <AppBar
        position="relative"
        className={styles.appBar}
      >
        <Toolbar>
          <Box mr={1}>
            <IconButton
              edge="start"
              color="primary"
              className={styles.burger}
              onClick={() => setIsDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <RouterLink to="/">
            <Box
              display="flex"
              alignItems="center"
            >
              <img
                src="/logo192.png"
                className={styles.logo}
                alt="Gourmet logo"
              />
              <Box
                display="flex"
                alignItems="flex-end"
                ml={2}
              >
                <Typography
                  variant="h5"
                  component="div"
                  color="primary"
                  className={styles.brand}
                >
                  Gourmet
                </Typography>
                <Box ml={1}>
                  <Typography className={styles.brandSubtitle}>
                    eat, review, love.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </RouterLink>
          <Box
            ml={4}
            mt={0.5}
            className={styles.menuContent}
          >
            <Link
              component={RouterLink}
              to="/restaurants"
              className={styles.menuLink}
            >
              Explore
            </Link>
          </Box>
          <Box
            className={styles.userMenu}
            flexGrow={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {isViewerLoaded ? viewer ? renderViewerMenu() : renderAuthenticationButtons() : null}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer,
  isViewerLoaded: s.booleans.isViewerLoaded,
})

export default connect(mapStateToProps)(NavigationBar)
