import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import roundRating from '../utils/roundRating'

const useStyles = makeStyles({
  rating: {
    lineHeight: 1,
  },
})

function RatingCircle({ rating = 10 }) {
  const styles = useStyles()

  if (rating === null) {
    return null
  }

  return (
    <Box position="relative">
      <CircularProgress
        size={80}
        color="primary"
        variant="static"
        value={rating * 10}
      />
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h6"
          className={styles.rating}
        >
          {roundRating(rating)}
        </Typography>
        <Typography>
          /10
        </Typography>
      </Box>
    </Box>
  )
}

export default RatingCircle
