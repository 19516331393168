import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ProfilePicture from './ProfilePicture'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'

const useStyles = makeStyles(theme => ({
  author: {
    lineHeight: 1.1,
    marginTop: theme.spacing(0.5),
  },
  date: {
    fontSize: 14,
  },
}))

function Review({ detailedRatings = false, noDeleteOption = false, review, label, pictureUrl, linkTo, onDelete = () => null, viewer }) {
  const styles = useStyles()
  const averageRating = Math.round((review.qualityOfFoodRating + review.serviceRating + review.settingRating) / 0.3) / 10
  const userIsViewer = viewer && review.userId === viewer.id

  function handleDeleteClick() {
    if (!window.confirm('Are you sure your want to delete this review?\nThis action is irreversible.')) {
      return
    }

    fetchApi('DELETE', `/reviews/${review.id}`)
    .then(() => {
      onDelete()
      dispatchAction('DELETE_REVIEW', { id: review.id })
    })
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <ProfilePicture url={pictureUrl} />
          <Box ml={1}>
            <RouterLink to={linkTo}>
              <Typography
                color="textPrimary"
                variant="h6"
                className={styles.author}
              >
                {label}
              </Typography>
            </RouterLink>
            <Typography className={styles.date}>
              {moment(review.createdAt).format('MMMM D, YYYY')}
            </Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Tooltip
            placement="top"
            title="Average rating"
          >
            <Typography variant="h6">
              {averageRating}
            </Typography>
          </Tooltip>
          {detailedRatings && (
            <>
              <Box ml={2}>
                <Tooltip
                  placement="top"
                  title="Quality of food"
                >
                  <Typography variant="h6">
                    {review.qualityOfFoodRating}
                  </Typography>
                </Tooltip>
              </Box>
              <Box ml={2}>
                <Tooltip
                  placement="top"
                  title="Service"
                >
                  <Typography variant="h6">
                    {review.serviceRating}
                  </Typography>
                </Tooltip>
              </Box>
              <Box ml={2}>
                <Tooltip
                  placement="top"
                  title="Setting"
                >
                  <Typography variant="h6">
                    {review.settingRating}
                  </Typography>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Typography>
          {review.comment}
        </Typography>
      </Box>
      {!!review.reply && (
        <Box mt={2}>
          <Typography variant="subtitle2">
            Reply:
          </Typography>
          <Typography>
            {review.reply}
          </Typography>
        </Box>
      )}
      {userIsViewer && !noDeleteOption && (
        <Box mt={2}>
          <Link onClick={handleDeleteClick}>Delete</Link>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer || {},
})

export default connect(mapStateToProps)(Review)
