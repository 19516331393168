import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'

import Review from './Review'

const nReviewsPerPage = 20

function RestaurantReviews({ user, viewer, reviews, restaurants }) {
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    fetchApi('GET', `/users/${user.id}/reviews?first=${nReviewsPerPage}&after=${page * nReviewsPerPage}`)
    .then(({ data, includes, count }) => {
      dispatchAction('READ_RESTAURANTS', includes.restaurants)
      dispatchAction('READ_REVIEWS', data)
      setCount(count)
    })
    .catch(error => {
      console.log('error', error)
      setIsError(true)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [page])

  if (isError) {
    return (
      <Typography>
        Error loading reviews :'(
      </Typography>
    )
  }

  const userIsViewer = viewer && user.id === viewer.id

  const userReviews = Object.values(reviews)
  .filter(review => review.userId === user.id && restaurants[review.restaurantId])
  .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

  if (!userReviews.length) {
    return (
      <>
        <Typography variant="h5">
          Reviews
        </Typography>
        {isLoading ? (
          <Box
            p={4}
            textAlign="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Typography>
            {userIsViewer ? (
              <>
                You did not authored any review yet. <Link component={RouterLink} to="/restaurants">Review a restaurant</Link>.
              </>
            ) : (
              'This user has not review.'
            )}
          </Typography>
        )}
      </>
    )
  }

  return (
    <>
      <Typography variant="h5">
        {count} Review{count > 1 ? 's' : ''}
      </Typography>
      {userReviews.map(review => (
        <Box
          key={review.id}
          mt={4}
        >
          <Review
            review={review}
            label={restaurants[review.restaurantId].name}
            pictureUrl={JSON.parse(restaurants[review.restaurantId].pictureUrls)[0]}
            linkTo={`/restaurant/${review.restaurantId}`}
            onDelete={() => setCount(count - 1)}
          />
        </Box>
      ))}
      {userReviews.length < count && (
        <Box mt={2}>
          <Button
            color="primary"
            onClick={() => setPage(page + 1)}
            disabled={isLoading}
          >
            Load more
          </Button>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer || {},
  reviews: s.reviews,
  restaurants: s.restaurants,
})

export default connect(mapStateToProps)(RestaurantReviews)
