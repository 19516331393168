import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import ReviewModal from './ReviewModal'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'

import Review from './Review'
import RatingCircle from './RatingCircle'

const nReviewsPerPage = 20

function RestaurantReviews({ restaurant, onNReviewsChange = () => null, reviews, users, viewer }) {
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    fetchApi('GET', `/restaurants/${restaurant.id}/reviews?first=${nReviewsPerPage}&after=${page * nReviewsPerPage}`)
    .then(({ data, includes, count }) => {
      dispatchAction('READ_USERS', includes.users)
      dispatchAction('READ_REVIEWS', data)
      setCount(count)
    })
    .catch(error => {
      console.log('error', error)
      setIsError(true)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [page])

  if (isError) {
    return (
      <Typography>
        Error loading reviews :'(
      </Typography>
    )
  }

  const restaurantReviews = Object.values(reviews)
  .filter(review => review.restaurantId === restaurant.id)
  .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

  function renderReviewButton() {
    if (!viewer) return null

    return (
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsReviewModalOpen(true)}
        >
          Author a review
        </Button>
      </Box>
    )
  }

  function renderReviewModal() {
    return isReviewModalOpen && (
      <ReviewModal
        restaurant={restaurant}
        onClose={() => setIsReviewModalOpen(false)}
        onSuccess={() => {
          setCount(count + 1)
          onNReviewsChange()
        }}
      />
    )
  }

  if (!restaurantReviews.length) {
    return (
      <>
        <Typography variant="h5">
          Reviews
        </Typography>
        {isLoading ? (
          <Box
            p={4}
            textAlign="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Typography>
            This restaurant has no review yet.
          </Typography>
        )}
        {renderReviewButton()}
        {renderReviewModal()}
      </>
    )
  }

  return (
    <>
      <Typography variant="h5">
        {count} Review{count > 1 ? 's' : ''}
      </Typography>
      {renderReviewButton()}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={4}
      >
        <Typography variant="h6">
          Quality of food
        </Typography>
        <Box
          ml={2}
          mr={6}
        >
          <RatingCircle rating={restaurant.averageQualityOfFoodRating} />
        </Box>
        <Typography variant="h6">
          Service
        </Typography>
        <Box
          ml={2}
          mr={6}
        >
          <RatingCircle rating={restaurant.averageServiceRating} />
        </Box>
        <Typography variant="h6">
          Setting
        </Typography>
        <Box ml={2}>
          <RatingCircle rating={restaurant.averageSettingRating} />
        </Box>
      </Box>
      {restaurantReviews.map(review => (
        <Box
          key={review.id}
          mt={4}
        >
          <Review
            review={review}
            label={`${users[review.userId].firstName} ${users[review.userId].lastName}`}
            pictureUrl={users[review.userId].profilePictureUrl}
            linkTo={`/user/${review.userId}`}
            onDelete={() => {
              setCount(count - 1)
              onNReviewsChange()
            }}
          />
        </Box>
      ))}
      {restaurantReviews.length < count && (
        <Box mt={2}>
          <Button
            color="primary"
            onClick={() => setPage(page + 1)}
            disabled={isLoading}
          >
            Load more
          </Button>
        </Box>
      )}
      {renderReviewModal()}
    </>
  )
}

const mapStateToProps = s => ({
  reviews: s.reviews,
  users: s.users,
  viewer: s.viewer,
})

export default connect(mapStateToProps)(RestaurantReviews)
