import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  logo: {
    width: 124,
    height: 124,
  },
  brand: {
    fontWeight: 900,
  },
  box: {
    borderColor: theme.palette.grey[300],
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  title: {
    position: 'relative',
    top: 56 / 2 - 3,
    margin: '0 auto',
    backgroundColor: 'white',
  },
}))

function AuthenticationLayout({ title, children }) {
  const styles = useStyles()

  const content = title ? renderContentBox() : renderContent()

  function renderContentBox() {
    return (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
      >
        <Box
          px={1}
          className={styles.title}
        >
          <Typography
            variant="h3"
            component="h1"
          >
            {title}
          </Typography>
        </Box>
        <Box
          p={4}
          className={styles.box}
        >
          {children}
        </Box>
      </Box>
    )
  }

  function renderContent() {
    return (
      <Box mt={5}>
        {children}
      </Box>
    )
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100vh"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={5}
          mb={0}
        >
          <img
            src="/logo192.png"
            alt="Gourmet logo"
            className={styles.logo}
          />
          <Typography
            variant="h4"
            component="div"
            color="primary"
            className={styles.brand}
          >
            Gourmet
          </Typography>
        </Box>

        {content}

        <Box my={5}>
          <Link
            to="/"
            component={RouterLink}
          >
            Home
          </Link>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Footer />
        </Box>
      </Box>
    </Container>
  )
}

export default AuthenticationLayout
