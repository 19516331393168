import React, { useRef, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import throttle from 'lodash.throttle'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import loadGoogleMapsScript from '../utils/loadGoogleMapsScript'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

const autocompleteService = { current: null }

function GoogleMapsLocationAutocomplete({ value = '', onValueChange, ...props }) {
  const [fetchLocation, setFetchLocation] = useState(() => () => null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const loaded = useRef(false)
  const styles = useStyles()

  window.confirmGoogleMapsHasLoaded = () => {
    console.log('Loaded Google Maps script')
  }

  if (typeof window !== 'undefined' && !loaded.current) {
    loadGoogleMapsScript()

    loaded.current = true
  }

  useEffect(() => {
    if (!loaded.current) return

    setFetchLocation(() => (
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200)
    ))
  }, [loaded.current])

  useEffect(() => {
    if (!window.google) return

    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }

    if (!autocompleteService.current) {
      return
    }

    if (inputValue === '') {
      setOptions([])

      return
    }

    fetchLocation({ input: inputValue }, results => {
      if (active) {
        setOptions(results || [])
      }
    })

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  function handleValueChange(event, { description }) {
    setInputValue(description)
    onValueChange(description)
  }

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      value={inputValue}
      onChange={handleValueChange}
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          {...props}
          onChange={event => setInputValue(event.target.value)}
          autoComplete="off"
        />
      )}
      renderOption={option => (
        <Grid
          container
          alignItems="center"
        >
          <Grid item>
            <LocationOnIcon className={styles.icon} />
          </Grid>
          <Grid item xs>
            {parse(
              option.structured_formatting.main_text,
              option.structured_formatting.main_text_matched_substrings.map(match => [match.offset, match.offset + match.length]),
            )
            .map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 500 : 400 }}
              >
                {part.text}
              </span>
            ))}
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      )}
    />
  )
}

export default GoogleMapsLocationAutocomplete
