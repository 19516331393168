import configuration from '../configuration'

const scriptId = 'google-maps'

function loadScript(src, position) {
  if (!position) return

  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', scriptId)
  script.src = src
  position.appendChild(script)
}

function loadGoogleMapsScript() {
  if (!document.querySelector(`#${scriptId}`)) {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${configuration.googleMapsApiKey}&libraries=places&callback=confirmGoogleMapsHasLoaded`,
      document.querySelector('head'),
    )
  }
}

export default loadGoogleMapsScript
