import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import roundRating from '../utils/roundRating'

import loadGoogleMapsScript from '../utils/loadGoogleMapsScript'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
})

function getRestaurantMarker() {
  class RestaurantMarker extends window.google.maps.OverlayView {

    constructor(map, restaurant, history) {
      super()

      this.coordinates = new window.google.maps.LatLng(restaurant.latitude, restaurant.longitude)
      this.restaurant = restaurant
      this.history = history
      this.setMap(map)
    }

    draw() {
      if (!this.markerElement) {
        this.markerElement = document.createElement('a')

        this.markerElement.href = `/restaurant/${this.restaurant.id}`

        this.markerElement.className = 'google-map-marker'

        this.markerElement.style.zIndex = this.restaurant.id

        const pictureUrl = JSON.parse(this.restaurant.pictureUrls)[0]

        this.markerElement.innerHTML = `
          <div class="google-map-marker-rating">${roundRating(this.restaurant.averageRating)}</div>
          <div class="google-map-marker-caret-inner"></div>
          <div class="google-map-marker-caret-outer"></div>
          <div class="google-map-marker-details">
            ${pictureUrl ? `
              <img src="${pictureUrl}" alt="restaurant" />
            ` : ''}
            <div class="google-map-maker-details-name">${this.restaurant.name}</div>
            <div class="google-map-maker-details-tags">
              ${JSON.parse(this.restaurant.tags).join(' · ')}
            </div>
          </div>
        `

        this.markerElement.onmouseenter = () => {
          this.markerElement.style.zIndex = 99999999
        }

        this.markerElement.onmouseleave = () => {
          this.markerElement.style.zIndex = this.restaurant.id
        }

        window.google.maps.event.addDomListener(this.markerElement, 'click', event => {
          event.preventDefault()

          this.history.push(`/restaurant/${this.restaurant.id}`)
        })

        const panes = this.getPanes()
        panes.overlayImage.appendChild(this.markerElement)
      }

      const point = this.getProjection().fromLatLngToDivPixel(this.coordinates)

      if (point) {
        this.markerElement.style.left = `${point.x - 25}px`
        this.markerElement.style.top = `${point.y - 56}px`
      }
    }

    remove() {
      if (this.markerElement) {
        this.markerElement.parentNode.removeChild(this.markerElement)
        this.markerElement = null
      }
    }

    getPosition() {
      return this.coordinates
    }
  }

  return RestaurantMarker
}

function computeCoordinatesCenterAndZoom(restaurants) {
  if (!restaurants.length) {
    return {
      latitude: 0,
      longitude: 0,
      zoom: 2,
    }
  }

  let minLatitude = Infinity
  let maxLatitude = -Infinity
  let minLongitude = Infinity
  let maxLongitude = -Infinity

  restaurants.forEach(restaurant => {
    if (restaurant.latitude < minLatitude) minLatitude = restaurant.latitude
    if (restaurant.latitude > maxLatitude) maxLatitude = restaurant.latitude
    if (restaurant.longitude < minLongitude) minLongitude = restaurant.longitude
    if (restaurant.longitude > maxLongitude) maxLongitude = restaurant.longitude
  })

  const longitudeDiff = maxLongitude - minLongitude
  let zoom

  if (longitudeDiff > 80) zoom = 3
  else if (longitudeDiff > 50) zoom = 4
  else if (longitudeDiff > 30) zoom = 5
  else if (longitudeDiff > 20) zoom = 6
  else if (longitudeDiff > 10) zoom = 7
  else if (longitudeDiff > 1) zoom = 8
  else if (longitudeDiff > 0.66) zoom = 9
  else if (longitudeDiff > 0.4) zoom = 10
  else if (longitudeDiff > 0.1) zoom = 12
  else zoom = 13

  return {
    latitude: (minLatitude + maxLatitude) / 2,
    longitude: (minLongitude + maxLongitude) / 2,
    zoom,
  }
}

function GoogleMapsMap({ restaurants }) {
  const [isGoogleMapScriptLoaded, setIsGoogleMapScriptLoaded] = useState(false)
  const [map, setMap] = useState(null)
  const [markers, setMarkers] = useState([])
  const loadedRef = useRef(false)
  const history = useHistory()
  const styles = useStyles()

  window.confirmGoogleMapsHasLoaded = () => {
    console.log('Loaded Google Maps script')
    setIsGoogleMapScriptLoaded(true)
  }

  function initMap() {
    if (!window.google) return

    const { latitude, longitude, zoom } = computeCoordinatesCenterAndZoom(restaurants)

    const mapElement = document.getElementById('map')
    const center = new window.google.maps.LatLng(latitude, longitude)
    const map = new window.google.maps.Map(mapElement, { center, zoom })

    setMap(map)
  }

  function placeMarkers() {
    markers.forEach(marker => {
      marker.setMap(null)
    })

    const RestaurantMarker = getRestaurantMarker()

    const nextMarkers = []

    restaurants.forEach(restaurant => {
      const marker = new RestaurantMarker(map, restaurant, history)

      nextMarkers.push(marker)
    })

    const { latitude, longitude, zoom } = computeCoordinatesCenterAndZoom(restaurants)
    const center = new window.google.maps.LatLng(latitude, longitude)

    map.setCenter(center)
    map.setZoom(zoom)

    setMarkers(nextMarkers)
  }

  useEffect(() => {
    if (!isGoogleMapScriptLoaded) return
    if (!map) {
      setTimeout(() => initMap(), 200)

      return
    }

    placeMarkers()
  }, [restaurants, map, isGoogleMapScriptLoaded])

  if (typeof window !== 'undefined' && !loadedRef.current) {
    loadGoogleMapsScript()

    if (!isGoogleMapScriptLoaded) {
      setIsGoogleMapScriptLoaded(true)
    }

    loadedRef.current = true
  }

  return (
    <div id="map" className={styles.root} />
  )
}

export default GoogleMapsMap
