import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import NotFound from '../NotFound'
import ApplicationLayout from '../../components/ApplicationLayout'
import RatingCircle from '../../components/RatingCircle'
import RestaurantReviews from '../../components/RestaurantReviews'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'

const useStyles = makeStyles(theme => ({
  carousel: {
    maxWidth: 656,
    margin: 'auto',
    userSelect: 'none',
    '& > div': {
      borderRadius: 8,
    },
  },
  meta: {
    fontSize: 14,
    textDecoration: 'underline',
    marginBottom: theme.spacing(1),
  },
}))

function RestaurantProfileScene({ restaurants }) {
  const [nCreatedReviews, setNCreatedReviews] = useState(0)
  const [isNotFound, setIsNotFound] = useState(false)
  const restaurantId = parseInt(useParams().restaurantId)
  const styles = useStyles()

  useEffect(() => {
    fetchApi('GET', `/restaurants/${restaurantId}`)
    .then(({ data }) => {
      dispatchAction('READ_RESTAURANT', data)
    })
    .catch(error => {
      console.log('error', error)
      setIsNotFound(true)
    })
  }, [restaurantId, nCreatedReviews])

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [])

  if (isNotFound) {
    return (
      <NotFound />
    )
  }

  const restaurant = restaurants[restaurantId]

  if (!restaurant) {
    return <ApplicationLayout loading />
  }

  const pictureUrls = JSON.parse(restaurant.pictureUrls)

  return (
    <>
      <Helmet>
        <title>
          {restaurant.name} - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout>
        <Container maxWidth="md">
          <Box
            py={5}
            px={2}
            display="flex"
            flexDirection="column"
          >
            <Carousel
              emulateTouch
              showThumbs={false}
              showStatus={false}
              className={styles.carousel}
            >
              {pictureUrls.map((url, i) => (
                <div key={url + i}>
                  <img
                    src={url}
                    alt={i}
                  />
                </div>
              ))}
            </Carousel>
            <Box
              textAlign="center"
              mt={3}
            >
              <Typography
                variant="h4"
                component="h1"
              >
                {restaurant.name}
              </Typography>
              <Box mt={1}>
                <Typography
                  variant="subtitle2"
                  className={styles.tags}
                >
                  {JSON.parse(restaurant.tags).join(' · ').toUpperCase()}
                </Typography>
              </Box>
            </Box>
            <Box
              mt={2}
              textAlign="center"
            >
              <RatingCircle rating={restaurant.averageRating} />
            </Box>
            <Box mt={2}>
              <Typography className={styles.meta}>
                Description:
              </Typography>
              <Typography>
                {restaurant.description}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography className={styles.meta}>
                Address:
              </Typography>
              <Typography>
                {restaurant.address} - <Link href={`https://www.google.com/maps/place/${restaurant.address.split(' ').map(part => window.encodeURIComponent(part)).join('+')}`} target="_blank">See on map</Link>.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography className={styles.meta}>
                Phone number:
              </Typography>
              <Typography>
                {restaurant.phoneNumber}
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography className={styles.meta}>
                Average price:
              </Typography>
              <Typography>
                ${restaurant.averagePrice}
              </Typography>
            </Box>
            <Box mt={5}>
              <RestaurantReviews restaurant={restaurant} onNReviewsChange={() => setNCreatedReviews(nCreatedReviews + 1)} />
            </Box>
          </Box>
        </Container>
      </ApplicationLayout>
    </>
  )
}

const mapStateToProps = s => ({
  restaurants: s.restaurants,
})

export default connect(mapStateToProps)(RestaurantProfileScene)
