import React, { useRef, useEffect, useState } from 'react'
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Rating from '@material-ui/lab/Rating'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

import ApplicationLayout from '../../components/ApplicationLayout'
import GoogleMapsMap from '../../components/GoogleMapsMap'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import roundRating from '../../utils/roundRating'

const useStyles = makeStyles(theme => ({
  left: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    flexGrow: 1,
  },
  right: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  paperSquared: {
    borderRadius: 0,
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
  },
  picture: {
    width: '100%',
  },
  picturePlacehoderWrapper: {
    height: '100%',
    overflow: 'hidden',
  },
  picturePlacehoder: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[300],
  },
}))

function usePreviousIfNotNull(value) {
  const ref = useRef()

  useEffect(() => {
    if (value !== null) {
      ref.current = value
    }
  }, [value])

  return ref.current
}

function getPageKey() {
  return `___page_home_${Math.random()}___`
}

let searchTimeoutId
const nRestaurantPerPage = 20

function RestaurantsScene({ restaurants }) {
  const listRef = useRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(0)
  const [previousPage, setPreviousPage] = useState(0)
  const [pageKey, setPageKey] = useState(getPageKey())
  const previousPageKey = usePreviousIfNotNull(pageKey)
  const history = useHistory()
  const location = useLocation()
  const styles = useStyles()

  const { sort = 'rating', search = '', ratingFilter = 0 } = queryString.parse(location.search)

  useEffect(() => {
    if (isLoading) return

    setIsLoading(true)

    fetchApi('GET', `/restaurants?first=${nRestaurantPerPage}&after=${nRestaurantPerPage * page}&sort=${sort}&ratingFilter=${ratingFilter}&search=${encodeURIComponent(search)}`)
    .then(({ data, count }) => {
      const isNewPage = previousPage === page
      const nextPageKey = isNewPage ? getPageKey() : pageKey

      dispatchAction(
        'READ_RESTAURANTS',
        data.map(restaurant => ({
          ...restaurant,
          [nextPageKey]: page,
        }))
      )
      setCount(count)
      setPageKey(nextPageKey)

      if (isNewPage) {
        listRef.current.scrollTop = 0
      }
    })
    .catch(error => {
      console.log('error', error)
      setIsError(true)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [page, sort, ratingFilter, search])

  if (isError) {
    return (
      <Typography>
        Error loading restaurants
      </Typography>
    )
  }

  const workingRestaurants = Object.values(restaurants)
  .filter(restaurant => restaurant[pageKey || previousPageKey] <= page)
  .sort((a, b) => {
    if (sort === 'rating') {
      return a.averageRating > b.averageRating ? -1 : 1
    }

    if (sort === 'reviews') {
      return a.nReviews > b.nReviews ? -1 : 1
    }

    return a.name < b.name ? -1 : 1
  })

  function handleSortChange(event) {
    setPageKey(null)
    setPage(0)
    setPreviousPage(0)
    history.replace(`/restaurants?sort=${event.target.value}&ratingFilter=${ratingFilter}&search=${search}`)
  }

  function handleSearchChange(event) {
    clearTimeout(searchTimeoutId)

    const { value } = event.target

    searchTimeoutId = setTimeout(() => {
      setPageKey(null)
      setPage(0)
      setPreviousPage(0)
      history.replace(`/restaurants?sort=${sort}&ratingFilter=${ratingFilter}&search=${encodeURIComponent(value)}`)
    }, 250)
  }

  function handleRatingChange(event, value) {
    setPageKey(null)
    setPage(0)
    setPreviousPage(0)
    history.replace(`/restaurants?sort=${sort}&ratingFilter=${value || 0}&search=${search}`)
  }

  function handleLoadMoreClick() {
    setPage(page + 1)
    setPreviousPage(page)
  }

  return (
    <>
      <Helmet>
        <title>
          Restaurants - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout footerHidden>
        <Box
          display="flex"
          height="calc(100vh - 64px)"
        >
          <Box
            flexGrow={1}
            flexBasis="38.197%"
            zIndex={999}
            display="flex"
            flexDirection="column"
          >
            <Paper
              elevation={6}
              className={styles.left}
            >
              <Paper
                elevation={4}
                className={styles.paperSquared}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={2}
                >
                  <Typography>
                    Sort by
                  </Typography>
                  <Box ml={1}>
                    <Select
                      value={sort}
                      onChange={handleSortChange}
                    >
                      <MenuItem value="rating">Rating</MenuItem>
                      <MenuItem value="reviews">Reviews</MenuItem>
                      <MenuItem value="name">Name</MenuItem>
                    </Select>
                  </Box>
                  <Box
                    ml={3}
                    flexGrow={1}
                    display="flex"
                    alignItems="flex-end"
                  >
                    <SearchOutlinedIcon />
                    <Box
                      ml={1}
                      flexGrow={1}
                    >
                      <TextField
                        fullWidth
                        margin="none"
                        placeholder="Search"
                        defaultValue={search}
                        onChange={handleSearchChange}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  pl={2}
                  pb={1}
                  display="flex"
                  alignItems="center"
                >
                  <Typography>
                    Filter
                  </Typography>
                  <Box ml={1}>
                    <Rating
                      name="averageRating"
                      max={10}
                      value={parseInt(ratingFilter)}
                      onChange={handleRatingChange}
                    />
                  </Box>
                </Box>
              </Paper>
              <Box
                ref={listRef}
                minHeight="calc(100% - 100px)"
                maxHeight="calc(100% - 100px)"
                overflow="auto"
                flexGrow={1}
                display="flex"
                flexDirection="column"
              >
                <Box flexGrow={1}>
                  {workingRestaurants.map(restaurant => (
                    <RestaurantListItem
                      key={restaurant.id}
                      restaurant={restaurant}
                    />
                  ))}
                  {!workingRestaurants.length && !isLoading && (
                    <Box p={2}>
                      <Typography>
                        Your search returned no result.
                      </Typography>
                    </Box>
                  )}
                  {!workingRestaurants.length && isLoading && (
                    <Box
                      pt={4}
                      textAlign="center"
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
                {workingRestaurants.length < count && (
                  <Box p={2}>
                    <Button
                      color="primary"
                      onClick={handleLoadMoreClick}
                      disabled={isLoading}
                    >
                      Load more
                    </Button>
                  </Box>
                )}
              </Box>
            </Paper>
          </Box>
          <Box
            flexGrow={1.618}
            flexBasis="61.803%"
            zIndex={0}
            className={styles.right}
          >
            <GoogleMapsMap restaurants={workingRestaurants} />
          </Box>
        </Box>

      </ApplicationLayout>
    </>
  )
}

function RestaurantListItem({ restaurant }) {
  const [imageNode, setImageNode] = useState(null)
  const styles = useStyles()

  const pictureUrl = JSON.parse(restaurant.pictureUrls)[0]

  useEffect(() => {
    if (!pictureUrl) return

    const image = new Image()

    image.onload = () => {
      setImageNode(
        <img
          src={pictureUrl}
          className={styles.picture}
          alt={restaurant.name}
        />
      )
    }

    image.src = pictureUrl
  }, [pictureUrl])

  return (
    <Box
      p={2}
      className={styles.listItem}
      display="flex"
      component={RouterLink}
      to={`/restaurant/${restaurant.id}`}
    >
      <Box
        flexBasis="61.803%"
        flexGrow={1.618}
      >
        {imageNode || (
          <div className={styles.picturePlacehoderWrapper}>
            <div className={styles.picturePlacehoder} />
          </div>
        )}
      </Box>
      <Box
        ml={2}
        flexBasis="38.197%"
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <Typography
          variant="h6"
          component="h1"
        >
          {restaurant.name}
        </Typography>
        <Box mt={1}>
          <Typography variant="subtitle2">
            {JSON.parse(restaurant.tags).join(' · ')}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography>
            Rating: {roundRating(restaurant.averageRating)}
          </Typography>
        </Box>
        <Typography>
          Reviews: {restaurant.nReviews}
        </Typography>
        <Box flexGrow={1} />
        <Box mt={2}>
          <Typography variant="body2">
            {restaurant.address}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = s => ({
  restaurants: s.restaurants,
})

export default connect(mapStateToProps)(RestaurantsScene)
