import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  profilePicture: {
    borderRadius: '50%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.grey[200],
    margin: 'auto',
  },
}))

const defaultUrl = 'https://storage.googleapis.com/gourmet-images/cleo-suggestion-de-plat-96557.jpg'

function ProfilePicture({ url = defaultUrl, size = 36 }) {
  const styles = useStyles()

  return (
    <div
      className={styles.profilePicture}
      style={{
        width: size,
        height: size,
        backgroundImage: `url(${url})`,
      }}
    />
  )
}

export default ProfilePicture
