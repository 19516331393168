import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import ApplicationLayout from '../../components/ApplicationLayout'
import GoogleMapsLocationAutocomplete from '../../components/GoogleMapsLocationAutocomplete'
import PictureUrlsInput from '../../components/PictureUrlsInput'

import configuration from '../../configuration'
import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import mapError from '../../utils/mapError'
import trimValues from '../../utils/trimValues'

import allTags from '../../data/tags.json'

function CreateRestaurantScene() {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [address, setAddress] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [averagePrice, setAveragePrice] = useState('')
  const [tags, setTags] = useState([])
  const [pictureUrls, setPictureUrls] = useState([])
  const [errorMessage, setErrorMessage] = useState(null)
  const [nameError, setNameError] = useState(null)
  const [descriptionError, setDescriptionError] = useState(null)
  const [addressError, setAddressError] = useState(null)
  const [phoneNumberError, setPhoneNumberError] = useState(null)
  const [averagePriceError, setAveragePriceError] = useState(null)
  const [tagsError, setTagsError] = useState(null)
  const history = useHistory()

  const errorSetters = {
    setErrorMessage,
    setNameError,
    setDescriptionError,
    setAddressError,
    setPhoneNumberError,
    setAveragePriceError,
    setTagsError,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    if (!tags.length) {
      setTagsError('You must provide at least one tag')

      return
    }

    setIsLoading(true)

    fetchApi('POST', '/restaurants', trimValues({
      name,
      description,
      address,
      phoneNumber,
      averagePrice,
      tags: JSON.stringify(tags),
      pictureUrls: JSON.stringify(pictureUrls),
    }))
    .then(({ data }) => {
      dispatchAction('READ_RESTAURANT', data)
      history.push(`/restaurant/${data.id}`)
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      setIsLoading(false)
      mapError(error.message, errorSetters)
    })
  }

  function handleTagsChange(event, value) {
    setTags([...value])
  }

  return (
    <>
      <Helmet>
        <title>
          New restaurant- Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout>
        <Container maxWidth="sm">
          <Box
            py={5}
            px={2}
            display="flex"
            flexDirection="column"
          >

            <Typography
              variant="h4"
              component="h1"
            >
              Create new restaurant
            </Typography>
            {!!errorMessage && (
              <Box mt={1}>
                <Alert severity="error">
                  {errorMessage}
                </Alert>
              </Box>
            )}

            <Box
              component="form"
              autoComplete="off"
              onSubmit={handleSubmit}
              mt={1}
            >

              <Box mt={2}>
                <Typography
                  variant="h6"
                  component="h2"
                >
                  Basic informations
                </Typography>
                <Typography variant="subtitle1">
                  Your restaurant's name, description and address.
                </Typography>
              </Box>
              <TextField
                fullWidth
                label="Name"
                type="text"
                margin="normal"
                color="secondary"
                value={name}
                onChange={event => setName(event.target.value)}
                error={!!nameError}
                helperText={nameError}
              />
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Description"
                type="text"
                margin="normal"
                color="secondary"
                value={description}
                onChange={event => setDescription(event.target.value)}
                error={!!descriptionError}
                helperText={descriptionError}
              />
              <GoogleMapsLocationAutocomplete
                label="Address"
                type="text"
                margin="normal"
                color="secondary"
                onValueChange={setAddress}
                error={!!addressError}
                helperText={addressError}
              />
              <TextField
                fullWidth
                label="Phone number"
                type="text"
                margin="normal"
                color="secondary"
                value={phoneNumber}
                onChange={event => setPhoneNumber(event.target.value)}
                error={!!phoneNumberError}
                helperText={phoneNumberError}
              />

              <Box mt={4}>
                <Typography
                  variant="h6"
                  component="h2"
                >
                  Cuisine and average price
                </Typography>
                <Typography variant="subtitle1">
                  What kind of cuisine do you propose and how much does it cost.
                </Typography>
              </Box>
              <Autocomplete
                multiple
                options={allTags}
                value={tags}
                onChange={handleTagsChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    fullWidth
                    type="text"
                    margin="normal"
                    color="secondary"
                    label="Cuisine, dietary options, tags..."
                    error={!!tagsError}
                    helperText={tagsError}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Average price"
                type="number"
                margin="normal"
                color="secondary"
                value={averagePrice}
                onChange={event => setAveragePrice(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
                error={!!averagePriceError}
                helperText={averagePriceError}
              />

              <Box mt={4}>
                <Typography
                  variant="h6"
                  component="h2"
                >
                  Add photos of your restaurant and your cuisine
                </Typography>
                <Typography variant="subtitle1">
                  You can add upto {configuration.restaurantMaxPictures} photos.<br />Select those that describe your restaurant best.
                </Typography>
              </Box>
              <PictureUrlsInput
                maxPictures={configuration.restaurantMaxPictures}
                pictureUrls={pictureUrls}
                setPictureUrls={setPictureUrls}
              />

              <Box mt={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  Create
                </Button>
              </Box>

            </Box>
          </Box>
        </Container>
      </ApplicationLayout>
    </>
  )
}

export default CreateRestaurantScene
