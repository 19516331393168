import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Home from './scenes/Home'
import Restaurants from './scenes/Restaurants'
import CreateRestaurant from './scenes/CreateRestaurant'
import UpdateRestaurant from './scenes/UpdateRestaurant'
import RestaurantProfile from './scenes/RestaurantProfile'
import RestaurantDashboard from './scenes/RestaurantDashboard'
import UserProfile from './scenes/UserProfile'
import Settings from './scenes/Settings'
import SignUp from './scenes/SignUp'
import SignIn from './scenes/SignIn'
import Legal from './scenes/Legal'
import AdministratorDashboard from './scenes/AdministratorDashboard'
import NotFound from './scenes/NotFound'

import AuthenticationBouncer from './components/AuthenticationBouncer'

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/restaurants">
          <Restaurants />
        </Route>
        <Route exact path="/restaurant/:restaurantId">
          <RestaurantProfile />
        </Route>
        <Route path="/restaurant/:restaurantId/dashboard">
          <AuthenticationBouncer>
            <RestaurantDashboard />
          </AuthenticationBouncer>
        </Route>
        <Route exact path="/create-restaurant">
          <AuthenticationBouncer>
            <CreateRestaurant />
          </AuthenticationBouncer>
        </Route>
        <Route exact path="/update-restaurant/:restaurantId">
          <AuthenticationBouncer>
            <UpdateRestaurant />
          </AuthenticationBouncer>
        </Route>
        <Route exact path="/user/:userId">
          <UserProfile />
        </Route>
        <Route exact path="/settings">
          <AuthenticationBouncer>
            <Settings />
          </AuthenticationBouncer>
        </Route>
        <Route exact path="/sign-up">
          <SignUp />
        </Route>
        <Route exact path="/sign-in">
          <SignIn />
        </Route>
        <Route exact path="/legal">
          <Legal />
        </Route>
        <Route path="/administrator-dashboard">
          <AuthenticationBouncer administratorOnly>
            <AdministratorDashboard />
          </AuthenticationBouncer>
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
