import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import NotFound from '../NotFound'
import ApplicationLayout from '../../components/ApplicationLayout'
import ProfilePicture from '../../components/ProfilePicture'
import RestaurantCard from '../../components/RestaurantCard'
import UserReviews from '../../components/UserReviews'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'

function UserProfileScene({ viewer, users, restaurants }) {
  const [isNotFound, setIsNotFound] = useState(false)
  const userId = parseInt(useParams().userId)

  const user = users[userId]
  const userRestaurants = Object.values(restaurants).filter(restaurant => restaurant.userId === userId)

  const userIsViewer = viewer && viewer.id === parseInt(userId)

  useEffect(() => {
    Promise.all([
      fetchApi('GET', `/users/${userId}`),
      fetchApi('GET', `/users/${userId}/restaurants`),
    ])
    .then(([{ data: userData }, { data: restaurantsData }]) => {
      dispatchAction('READ_USER', userData)
      dispatchAction('READ_RESTAURANTS', restaurantsData)
    })
    .catch(error => {
      console.log('error', error)
      setIsNotFound(true)
    })
  }, [userId])

  if (isNotFound) {
    return (
      <NotFound />
    )
  }

  if (!user) {
    return <ApplicationLayout loading />
  }

  const userFullName = `${user.firstName} ${user.lastName}`

  function renderRestaurantsSectionForViewer() {
    if (!userRestaurants.length) {
      return (
        <Typography>
          You do not own any restaurant yet. <Link component={RouterLink} to="/create-restaurant">Create one</Link>.
        </Typography>
      )
    }

    return (
      <>
        {renderRestaurantsSectionForEveryOne()}
        <Box mt={3}>
          <Link component={RouterLink} to="/create-restaurant">Create new restaurant</Link>
        </Box>
      </>
    )
  }

  function renderRestaurantsSectionForUser() {
    if (!userRestaurants) {
      return null
    }

    return renderRestaurantsSectionForEveryOne()
  }

  function renderRestaurantsSectionForEveryOne() {
    return (
      <Grid container spacing={2}>
        {userRestaurants.map(restaurant => (
          <Grid item key={restaurant.id}>
            <RestaurantCard restaurant={restaurant}>
              {userIsViewer && (
                <Box
                  flexGrow={1}
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  mt={2}
                >
                  <Link
                    component={RouterLink}
                    to={`/restaurant/${restaurant.id}/dashboard`}
                  >
                    Dashboard
                  </Link>
                  <Link
                    component={RouterLink}
                    to={`/update-restaurant/${restaurant.id}`}
                  >
                    Edit
                  </Link>
                </Box>
              )}
            </RestaurantCard>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      <Helmet>
        <title>
          {userFullName} - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout>
        <Container maxWidth="md">
          <Box
            py={5}
            px={2}
            display="flex"
            flexDirection="column"
          >
            <Box textAlign="center">
              <ProfilePicture
                url={user.profilePictureUrl}
                size={128}
              />
              <Box mt={3}>
                <Typography
                  variant="h4"
                  component="h1"
                >
                  {userFullName}
                </Typography>
              </Box>
            </Box>

            {(userIsViewer || !!userRestaurants.length) && (
              <Box
                mt={5}
                component="section"
              >
                <Typography
                  variant="h5"
                  component="h2"
                >
                  Restaurants
                </Typography>
                <Box mt={2}>
                  {userIsViewer ? renderRestaurantsSectionForViewer() : renderRestaurantsSectionForUser()}
                </Box>
              </Box>
            )}

            <Box
              mt={5}
              component="section"
            >
              <UserReviews user={user} />
            </Box>

          </Box>
        </Container>
      </ApplicationLayout>
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer,
  restaurants: s.restaurants,
  users: s.users,
})

export default connect(mapStateToProps)(UserProfileScene)
