const isProduction = process.env.NODE_ENV === 'production'

const configuration = {
  apiHost: isProduction ? 'https://api.gourmet.rocks' : 'http://localhost:8080',
  tokenKey: 'gourmet-api-token',
  googleMapsApiKey: 'AIzaSyAZOmiHlqBlzFUGb_fr0OHxUIkQxP9B9EM',
  restaurantMaxPictures: 12,
}

export default configuration
