import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import NavigationBar from './NavigationBar'
import FullScreenSpinner from './FullScreenSpinner'
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
  childrenHidden: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  childrenHiddenPlaceholder: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

function ApplicationLayout({
  loading = false,
  noMobile = false,
  footerHidden = false,
  footerAbsolute = false,
  children,
}) {
  const styles = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      position="relative"
    >
      <NavigationBar />
      {loading && <FullScreenSpinner />}
      <Box
        flexGrow={1}
        className={noMobile ? styles.childrenHidden : null}
      >
        {children}
      </Box>
      {noMobile && (
        <Box
          flexGrow={1}
          p={4}
          textAlign="center"
          className={styles.childrenHiddenPlaceholder}
        >
          <Typography>
            This content is only available on desktop resolutions.
          </Typography>
        </Box>
      )}
      {!footerHidden && (
        <Footer footerAbsolute={footerAbsolute} />
      )}
    </Box>
  )
}

export default ApplicationLayout
