import React from 'react'
import { Route, Redirect, Link as RouterLink, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import ApplicationLayout from '../../components/ApplicationLayout'
import AdministratorDashboardUsers from './AdministratorDashboardUsers'
import AdministratorDashboardRestaurants from './AdministratorDashboardRestaurants'
import AdministratorDashboardReviews from './AdministratorDashboardReviews'

const urlsEnds = [
  'users',
  'restaurants',
  'reviews',
]

function AdministratorDashboardScene() {
  const { pathname } = useLocation()

  let urlBase = pathname.split('/')

  const urlEnd = urlBase.pop()

  urlBase = urlBase.join('/')

  const urlEndIndex = urlsEnds.indexOf(urlEnd)

  return (
    <>
      <Helmet>
        <title>
          Administrator dashboard - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout noMobile>
        <Paper elevation={4}>
          <Box
            pt={2}
            textAlign="center"
          >
            <Typography
              variant="h4"
              component="h1"
            >
              Administrator dashboard
            </Typography>
          </Box>
          <Tabs
            centered
            textColor="primary"
            indicatorColor="primary"
            value={urlEndIndex === -1 ? 0 : urlEndIndex}
          >
            <Tab
              label="Users"
              component={RouterLink}
              to={`${urlBase}/users`}
            />
            <Tab
              label="Restaurants"
              component={RouterLink}
              to={`${urlBase}/restaurants`}
            />
            <Tab
              label="Reviews"
              component={RouterLink}
              to={`${urlBase}/reviews`}
            />
          </Tabs>
        </Paper>
        <Box
          p={2}
        >
          <Route exact path="/administrator-dashboard/users">
            <AdministratorDashboardUsers />
          </Route>
          <Route exact path="/administrator-dashboard/restaurants">
            <AdministratorDashboardRestaurants />
          </Route>
          <Route exact path="/administrator-dashboard/reviews">
            <AdministratorDashboardReviews />
          </Route>
          <Redirect exact from="/administrator-dashboard" to="/administrator-dashboard/users" />
        </Box>
      </ApplicationLayout>
    </>
  )
}

export default AdministratorDashboardScene
