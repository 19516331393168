import { combineReducers, applyMiddleware, compose, createStore } from 'redux'

import booleans from './reducers/booleans'
import restaurants from './reducers/restaurants'
import reviews from './reducers/reviews'
import users from './reducers/users'
import viewer from './reducers/viewer'

function loggerMiddleware() {
  return next => action => {
    console.log('Action', action.type, action.payload)

    return next(action)
  }
}

const reducer = combineReducers({
  booleans,
  restaurants,
  reviews,
  users,
  viewer,
})

const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
  : compose

const middlewares = []

if (process.env.NODE_ENV !== 'production') middlewares.push(loggerMiddleware)

const enhancer = composeEnhancers(applyMiddleware(...middlewares))

const store = createStore(reducer, {}, enhancer)

export default store
