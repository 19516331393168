import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import indigo from '@material-ui/core/colors/indigo'
import grey from '@material-ui/core/colors/grey'

const theme = createMuiTheme({
  palette: {
    primary: red,
    secondary: indigo,
    grey,
  },
})

export default theme
