import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Pagination from '@material-ui/lab/Pagination'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import trimValues from '../../utils/trimValues'

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
})

const nItemsPerPage = 20

const typeToComponent = {
  string: StringCeil,
  'string[]': StringsCeil,
  number: NumberCeil,
  boolean: BooleanCeil,
}

function AdministratorDashboardPage({ actionTypeSuffix, fetchUrl, columns, data }) {
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const styles = useStyles()

  useEffect(() => {
    fetchApi('GET', `${fetchUrl}?first=${nItemsPerPage}&after=${nItemsPerPage * page}`)
    .then(({ data, count }) => {
      dispatchAction(`READ_${actionTypeSuffix}S`, data.map(item => ({ ...item, page })))
      setCount(count)
    })
    .catch(error => {
      console.log('error', error)
      setIsError(true)
    })
  }, [fetchUrl, page])

  if (isError) {
    return (
      <Typography>
        Error loading data
      </Typography>
    )
  }

  const workingData = Object.values(data).sort((a, b) => a.id < b.id ? -1 : 1).filter(item => item.page === page)

  return (
    <>
      {!!errorMessage && (
        <Box mb={2}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                id
              </TableCell>
              {Object.keys(columns).map(column => (
                <TableCell key={column}>
                  {column}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {workingData.map(item => (
              <Row
                key={item.id}
                item={item}
                columns={columns}
                actionTypeSuffix={actionTypeSuffix}
                fetchUrl={fetchUrl}
                setErrorMessage={setErrorMessage}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        py={2}
        display="flex"
        justifyContent="flex-end"
      >
        <Pagination
          shape="rounded"
          count={Math.ceil(count / nItemsPerPage)}
          page={page + 1}
          onChange={(event, value) => setPage(value - 1)}
        />

      </Box>
    </>
  )
}

function Row({ item, columns, actionTypeSuffix, fetchUrl, setErrorMessage }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [updatedValue, setUpdatedValue] = useState(item)

  const columnValues = Object.entries(columns)

  function handleUpdateClick() {
    if (isLoading) return

    setErrorMessage()

    const data = { id: item.id }

    Object.keys(columns).forEach(key => {
      if (columns[key].doesNotUpdate) return

      data[key] = updatedValue[key]
    })

    console.log('data', data)

    fetchApi('PUT', `${fetchUrl}/${item.id}`, trimValues(data))
    .then(({ data }) => {
      dispatchAction(`UPDATE_${actionTypeSuffix}`, data)
      setIsUpdating(false)
    })
    .catch(error => {
      console.log('error', error)
      setErrorMessage(error.message)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  function handleDeleteClick() {
    if (isLoading) return

    if (!window.confirm('Are you sure you want to delete this item?\nThis action is irreversible.')) {
      return
    }

    setErrorMessage()

    fetchApi('DELETE', `${fetchUrl}/${item.id}`)
    .then(({ deletedUsers, deletedRestaurants, deletedReviews }) => {
      if (deletedUsers) dispatchAction('DELETE_USERS', deletedUsers)
      if (deletedRestaurants) dispatchAction('DELETE_RESTAURANTS', deletedRestaurants)
      if (deletedReviews) dispatchAction('DELETE_REVIEWS', deletedReviews)
    })
    .catch(error => {
      console.log('error', error)
      setErrorMessage(error.message)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <TableRow>
      <TableCell>
        {item.id}
      </TableCell>
      {columnValues.map(([key, { type, possibleValues = [], doesNotUpdate }]) => {
        const Component = typeToComponent[type]

        return (
          <Component
            key={key}
            value={isUpdating ? updatedValue[key] : item[key]}
            setValue={value => setUpdatedValue({ ...updatedValue, [key]: value })}
            possibleValues={possibleValues}
            isUpdating={!doesNotUpdate && isUpdating}
          />
        )
      })}
      <TableCell>
        {isUpdating ? (
          <Button
            size="small"
            color="primary"
            variant="contained"
            disabled={isLoading}
            onClick={handleUpdateClick}
          >
            Done
          </Button>
        ) : (
          <Box display="flex">
            <Button
              size="small"
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() => setIsUpdating(true)}
            >
              Update
            </Button>
            <Box ml={1}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                disabled={isLoading}
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
            </Box>
          </Box>
        )}
      </TableCell>
    </TableRow>
  )
}

function StringCeil({ isUpdating, value, setValue }) {
  if (isUpdating) {
    return (
      <TableCell>
        <TextField
          fullWidth
          type="text"
          margin="normal"
          color="secondary"
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </TableCell>
    )
  }

  return (
    <TableCell>
      {value}
    </TableCell>
  )
}

function StringsCeil({ isUpdating, value, setValue, possibleValues }) {
  const values = JSON.parse(value)

  if (isUpdating) {
    return (
      <TableCell>
        <Autocomplete
          multiple
          freeSolo
          options={possibleValues}
          value={values}
          onChange={(event, nextValue) => setValue(JSON.stringify(nextValue))}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              type="text"
              margin="normal"
              color="secondary"
            />
          )}
        />
      </TableCell>
    )
  }

  return (
    <TableCell>
      {values.join(', ')}
    </TableCell>
  )
}

function BooleanCeil({ isUpdating, value, setValue }) {
  if (isUpdating) {
    return (
      <TableCell>
        <Checkbox
          checked={value}
          color="default"
          onChange={(event, checked) => setValue(checked)}
        />
      </TableCell>
    )
  }

  return (
    <TableCell>
      <Checkbox
        readOnly
        disableRipple
        checked={value}
        color="default"
      />
    </TableCell>
  )
}

function NumberCeil({ isUpdating, value, setValue }) {
  if (isUpdating) {
    return (
      <TableCell>
        <TextField
          fullWidth
          type="number"
          margin="normal"
          color="secondary"
          value={value}
          onChange={event => setValue(event.target.value)}
        />
      </TableCell>
    )
  }

  return (
    <TableCell>
      {value}
    </TableCell>
  )
}

const mapStateToProps = (s, p) => ({
  data: s[p.stateKey],
})

export default connect(mapStateToProps)(AdministratorDashboardPage)
