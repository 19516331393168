import React from 'react'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

function FullScreenSpinner() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 64px - 72px)"
    >
      <CircularProgress />
    </Box>
  )
}

export default FullScreenSpinner
