import React, { useEffect, useState } from 'react'
import { Route, Redirect, Link as RouterLink, useParams, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'

import NotFound from '../NotFound'
import ApplicationLayout from '../../components/ApplicationLayout'

import RestaurantDashboardReviews from './RestaurantDashboardReviews'
import RestaurantDashboardAnalytics from './RestaurantDashboardAnalytics'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'

function RestaurantDashboardScene({ viewer, restaurants, reviews, users }) {
  const [isLoading, setIsLoading] = useState(true)
  const [isNotFound, setIsNotFound] = useState(false)
  const restaurantId = (parseInt(useParams().restaurantId))
  const { pathname } = useLocation()

  useEffect(() => {
    fetchApi('GET', `/restaurants/${restaurantId}/dashboard-data`)
    .then(({ data, includes }) => {
      dispatchAction('READ_RESTAURANT', data)
      dispatchAction('READ_USERS', includes.users)
      dispatchAction('READ_REVIEWS', includes.reviews)
    })
    .catch(error => {
      console.log('error', error)
      setIsNotFound(true)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }, [restaurantId])

  if (isNotFound) {
    return (
      <NotFound />
    )
  }

  const restaurant = restaurants[restaurantId]

  if (!restaurant || isLoading) {
    return <ApplicationLayout loading />
  }

  if (restaurant.userId !== viewer.id) {
    return (
      <NotFound />
    )
  }

  const restaurantReviews = Object.values(reviews).filter(review => review.restaurantId === restaurantId)

  const isReviews = pathname.endsWith('reviews')
  let urlBase = pathname.split('/')

  urlBase.pop()

  urlBase = urlBase.join('/')

  return (
    <>
      <Helmet>
        <title>
          {restaurant.name} dashboard - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout noMobile>
        <Paper elevation={4}>
          <Box
            pt={2}
            textAlign="center"
          >
            <Typography
              variant="h4"
              component="h1"
            >
              {restaurant.name} dashboard
            </Typography>
          </Box>
          <Tabs
            value={isReviews ? 0 : 1}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              label="Reviews"
              component={RouterLink}
              to={`${urlBase}/reviews`}
            />
            <Tab
              label="Analytics"
              component={RouterLink}
              to={`${urlBase}/analytics`}
            />
          </Tabs>
        </Paper>
        <Box
          p={2}
        >
          <Route exact path="/restaurant/:restaurantId/dashboard/reviews">
            <RestaurantDashboardReviews
              reviews={restaurantReviews}
              users={users}
            />
          </Route>
          <Route exact path="/restaurant/:restaurantId/dashboard/analytics">
            <RestaurantDashboardAnalytics
              restaurant={restaurant}
              reviews={restaurantReviews}
            />
          </Route>
          <Route
            exact
            path="/restaurant/:restaurantId/dashboard"
            render={props => (
              <Redirect to={`/restaurant/${props.match.params.restaurantId}/dashboard/reviews`} />
            )}
          />
        </Box>
      </ApplicationLayout>
    </>
  )
}

const mapStateToProps = s => ({
  restaurants: s.restaurants,
  viewer: s.viewer,
  reviews: s.reviews,
  users: s.users,
})

export default connect(mapStateToProps)(RestaurantDashboardScene)
