import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'

import ApplicationLayout from '../../components/ApplicationLayout'

const useStyles = makeStyles(theme => ({
  leadImage: {
    backgroundImage: 'url("/food.png")',
    height: 'calc(100vh - 64px)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 56px)',
    },
  },
  leadBox: {
    backgroundColor: 'white',
    borderRadius: 8,
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  lead: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
  },
  input: {
    flexGrow: 1,
  },
  searchButton: {
    maxWidth: 40,
  },
}))

const SearchButton = withStyles({
  root: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    paddingRight: 5,
    height: 40,
    boxShadow: 'none',
  },
})(Button)

const SearchTextField = withStyles(theme => ({
  root: {
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '& .MuiFormLabel-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        lineHeight: 1.4,
      },
    },
  },
}))(TextField)

function HomeScene() {
  const [inputValue, setInputValue] = useState('')
  const history = useHistory()
  const styles = useStyles()

  function handleSubmit(event) {
    event.preventDefault()

    history.push(`/restaurants?sort=rating&search=${encodeURIComponent(inputValue)}`)
  }

  return (
    <>
      <Helmet>
        <title>
          Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout footerAbsolute>
        <Box position="relative">

          <Box className={styles.leadImage} />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
          >
            <Box
              mx={2}
              className={styles.leadBox}
            >
              <Typography
                variant="h2"
                component="h1"
                className={styles.lead}
              >
                Meet the world's top restaurants
              </Typography>

              <Box
                display="flex"
                mt={3}
                component="form"
                onSubmit={handleSubmit}
              >
                <SearchTextField
                  variant="outlined"
                  label="Cuisine, restaurant's name..."
                  size="small"
                  value={inputValue}
                  onChange={event => setInputValue(event.target.value)}
                />
                <SearchButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  startIcon={<SearchOutlinedIcon />}
                  onClick={handleSubmit}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </ApplicationLayout>
    </>
  )
}

export default HomeScene
