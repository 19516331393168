import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import ApplicationLayout from '../../components/ApplicationLayout'

function NotFoundScene() {
  return (
    <>
      <Helmet>
        <title>
          Not found - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="calc(100vh - 64px - 72px)"
        >
          <Typography variant="h1">
            Page not found
          </Typography>
          <Box mt={4}>
            <Link
              component={RouterLink}
              to="/"
            >
              Home
            </Link>
          </Box>
        </Box>
      </ApplicationLayout>
    </>
  )
}

export default NotFoundScene
