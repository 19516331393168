import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import Rating from '@material-ui/lab/Rating'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'
import mapError from '../utils/mapError'
import trimValues from '../utils/trimValues'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4),
    width: 512,

    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    minWidth: 24,
  },
}))

function nullIfMinusOne(value) {
  return value === -1 ? null : value
}

const progressions = [
  'qualityOfFood',
  'service',
  'setting',
]

function ReviewModal({ restaurant, onClose, onSuccess = () => null }) {
  const [isLoading, setIsLoading] = useState(false)
  const [qualityOfFoodRating, setQualityOfFoodRating] = useState(-1)
  const [serviceRating, setServiceRating] = useState(-1)
  const [settingRating, setSettingRating] = useState(-1)
  const [comment, setComment] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [commentError, setCommentError] = useState(null)
  const [progress, setProgress] = useState(new Set())
  const styles = useStyles()

  const errorSetters = {
    setCommentError,
    setErrorMessage,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    setIsLoading(true)

    fetchApi('POST', '/reviews', trimValues({
      qualityOfFoodRating,
      serviceRating,
      settingRating,
      comment,
      restaurantId: restaurant.id,
    }))
    .then(({ data }) => {
      dispatchAction('READ_REVIEW', data)
      onSuccess()
      onClose()
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      setIsLoading(false)
      mapError(error.message, errorSetters)
    })
  }

  function handleRate(setter, progression) {
    return (event, value) => {
      setter(value)

      if (value === null) {
        const nextProgress = new Set([...progress])

        nextProgress.delete(progression)

        setProgress(nextProgress)
      }
      else {
        setProgress(new Set([...progress, progression]))
      }
    }
  }

  return (
    <Modal
      open
      onClose={onClose}
      className={styles.modal}
    >
      <Paper className={styles.paper}>

        <Typography
          variant="h4"
          component="h1"
        >
          Review {restaurant.name}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          mt={2}
        >
          {!!errorMessage && (
            <Box mb={1}>
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}
          <RatingBox
            label="Quality of food"
            rating={qualityOfFoodRating}
            setRating={handleRate(setQualityOfFoodRating, 'qualityOfFood')}
          />
          <RatingBox
            label="Service"
            rating={serviceRating}
            setRating={handleRate(setServiceRating, 'service')}
            mt={1}
          />
          <RatingBox
            label="Setting"
            rating={settingRating}
            setRating={handleRate(setSettingRating, 'setting')}
            mt={1}
          />
          <TextField
            fullWidth
            multiline
            rows={6}
            label="Comment"
            type="comment"
            margin="normal"
            color="secondary"
            value={comment}
            onChange={event => setComment(event.target.value)}
            error={!!commentError}
            helperText={commentError}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading || !(comment.trim() && progressions.every(p => progress.has(p)))}
            >
              Create review
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

function RatingBox({ label, rating, setRating, ...props }) {
  const [ratingLabel, setRatingLabel] = useState(-1)
  const styles = useStyles()

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...props}
    >
      <Typography variant="h6">
        {label}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
      >
        <Rating
          max={10}
          name={label}
          value={rating}
          onChange={setRating}
          onChangeActive={(event, value) => setRatingLabel(value)}
        />
        <Box
          className={styles.label}
          ml={1}
        >
          {nullIfMinusOne(ratingLabel) || nullIfMinusOne(rating)}
        </Box>
      </Box>
    </Box>
  )
}

export default ReviewModal
