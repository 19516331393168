/*
  The flattened users data from the database
*/

const initialState = {}

function users(state = initialState, action) {
  switch (action.type) {
    case 'READ_USER':
    case 'SET_VIEWER': {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    }

    case 'READ_USERS': {
      const nextState = { ...state }

      action.payload.forEach(user => {
        nextState[user.id] = user
      })

      return nextState
    }

    case 'UPDATE_USER': {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      }
    }

    case 'DELETE_USER': {
      const nextState = { ...state }

      delete nextState[action.payload.id]

      return nextState
    }

    case 'DELETE_USERS': {
      const nextState = { ...state }

      action.payload.forEach(({ id }) => {
        delete nextState[id]
      })

      return nextState
    }

    default: {
      return state
    }
  }
}

export default users
