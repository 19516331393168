import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import NotFound from '../scenes/NotFound'

import FullScreenSpinner from './FullScreenSpinner'

function AuthenticationBouncer({ administratorOnly = false, children, viewer, isViewerLoaded }) {
  const history = useHistory()

  if (isViewerLoaded && !viewer) {
    history.push('/sign-in')
  }

  if (isViewerLoaded && administratorOnly && !(viewer && viewer.isAdministrator)) {
    return (
      <NotFound />
    )
  }

  return isViewerLoaded ? viewer ? children : <NotFound /> : <FullScreenSpinner />
}

const mapStateToProps = s => ({
  viewer: s.viewer,
  isViewerLoaded: s.booleans.isViewerLoaded,
})

export default connect(mapStateToProps)(AuthenticationBouncer)
