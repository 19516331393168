/*
  The flattened restaurants data from the database
*/

const initialState = {}

function restaurants(state = initialState, action) {
  switch (action.type) {
    case 'READ_RESTAURANT': {
      return {
        ...state,
        [action.payload.id]: action.payload,
      }
    }

    case 'READ_RESTAURANTS': {
      const nextState = { ...state }

      action.payload.forEach(restaurant => {
        nextState[restaurant.id] = restaurant
      })

      return nextState
    }

    case 'UPDATE_RESTAURANT': {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload,
        },
      }
    }

    case 'DELETE_RESTAURANT': {
      const nextState = { ...state }

      delete nextState[action.payload.id]

      return nextState
    }

    case 'DELETE_RESTAURANTS': {
      const nextState = { ...state }

      action.payload.forEach(({ id }) => {
        delete nextState[id]
      })

      return nextState
    }

    default: {
      return state
    }
  }
}

export default restaurants
