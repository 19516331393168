import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import AuthenticationLayout from '../../components/AuthenticationLayout'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import mapError from '../../utils/mapError'
import trimValues from '../../utils/trimValues'

function SignUpScene({ viewer }) {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [hasAgreedToLegalTerms, setHasAgreedToLegalTerms] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const history = useHistory()

  const inputLabelProps = { shrink: true }

  const errorSetters = {
    setErrorMessage,
    setEmailError,
    setPasswordError,
    setFirstNameError,
    setLastNameError,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    if (!hasAgreedToLegalTerms) {
      setErrorMessage('You must agree to Gourmet\'s Privacy Policy and Terms of Services')

      return
    }

    setIsLoading(true)

    fetchApi('POST', '/users', trimValues({
      email,
      password,
      firstName,
      lastName,
    }))
    .then(({ data }) => {
      dispatchAction('SET_VIEWER', data)
      history.push('/restaurants')
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      setIsLoading(false)
      mapError(error.message, errorSetters)
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Sign up - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <AuthenticationLayout title="Sign up">
        {!!viewer && (
          <Alert severity="info">
            You are already signed in as {viewer.firstName} {viewer.lastName}
          </Alert>
        )}
        {!!errorMessage && (
          <Box mt={viewer ? 1 : 0}>
            <Alert severity="error">
              {errorMessage}
            </Alert>
          </Box>
        )}
        <form onSubmit={handleSubmit}>

          <TextField
            fullWidth
            label="Email"
            type="email"
            autoComplete="email"
            placeholder="alain.ducasse@chef.com"
            margin="normal"
            InputLabelProps={inputLabelProps}
            color="secondary"
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <Box display="flex">
            <Box flexGrow={1}>
              <TextField
                fullWidth
                label="First name"
                placeholder="Alain"
                autoComplete="given-name"
                margin="normal"
                InputLabelProps={inputLabelProps}
                color="secondary"
                value={firstName}
                onChange={event => setFirstName(event.target.value)}
                error={!!firstNameError}
                helperText={firstNameError}
              />
            </Box>
            <Box
              flexGrow={1}
              ml={2}
            >
              <TextField
                fullWidth
                label="Last name"
                placeholder="Ducasse"
                autoComplete="family-name"
                margin="normal"
                InputLabelProps={inputLabelProps}
                color="secondary"
                value={lastName}
                onChange={event => setLastName(event.target.value)}
                error={!!lastNameError}
                helperText={lastNameError}
              />
            </Box>
          </Box>
          <TextField
            fullWidth
            label="Password (must be at least 8 characters long)"
            placeholder="••••••••"
            autoComplete="new-password"
            margin="normal"
            InputLabelProps={inputLabelProps}
            color="secondary"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Box mt={2}>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  checked={hasAgreedToLegalTerms}
                  onChange={() => setHasAgreedToLegalTerms(!hasAgreedToLegalTerms)}
                />
              )}
              label={(
                <Typography>
                  I have read and agreed to Gourmet's<br /><Link to="/legal" component={RouterLink}>Privacy Policy and Terms of Service</Link>.
                </Typography>
              )}
            />
          </Box>

          <Box
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Continue
            </Button>
            <Box ml={2}>
              <Typography>
                Already have an account? <Link to="/sign-in" component={RouterLink}>Sign in</Link>.
              </Typography>
            </Box>
          </Box>

        </form>
      </AuthenticationLayout>
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer,
})

export default connect(mapStateToProps)(SignUpScene)
