import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined'
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined'

import { randomInteger, randomArrayItem } from '../utils/random'

import stockPhotosUrls from '../data/stock-photos-urls.json'

const useStyles = makeStyles({
  pictureWrapper: {
    position: 'relative',
    userSelect: 'none',
    '& > div': {
      opacity: 0,
      color: 'white',
      backgroundColor: '#00000040',
      transition: 'opacity 0.15s linear',
    },
    '&:hover > div': {
      opacity: 1,
    },
  },
  picture: {
    maxWidth: '100%',
  },
})

function PictureUrlsInput({ pictureUrls, setPictureUrls, maxPictures }) {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const styles = useStyles()

  function handleSubmit() {
    if (isLoading) return

    if (pictureUrls.length === maxPictures) {
      setErrorMessage(`You cannot add more than ${maxPictures} photos.`)

      return
    }

    setIsLoading(true)
    setErrorMessage(null)

    const image = new Image()

    image.onload = () => {
      setIsLoading(false)
      setPictureUrls([...pictureUrls, inputValue])
      setInputValue('')
    }

    image.onerror = () => {
      setErrorMessage('Invalid URL')
    }

    image.src = inputValue
  }

  function handleUseStockPhotos() {
    if (isLoading) return

    const remainingSlots = maxPictures - pictureUrls.length

    if (remainingSlots === 0) {
      setErrorMessage(`You cannot add more than ${maxPictures} photos.`)

      return
    }

    const nAdded = randomInteger(1, remainingSlots)

    const urls = []

    for (let i = 0; i < nAdded; i++) {
      let url

      do {
        url = randomArrayItem(stockPhotosUrls)
      }
      while (urls.includes(url))

      urls.push(url)
    }

    setPictureUrls([...pictureUrls, ...urls])
  }

  function handleDeletePicture(i) {
    return () => {
      const nextPictureUrls = pictureUrls.slice()

      nextPictureUrls.splice(i, 1)

      setPictureUrls(nextPictureUrls)
    }
  }

  function handleMovePicture(i, delta) {
    const j = i + delta

    return () => {
      const nextPictureUrls = pictureUrls.slice();

      ([nextPictureUrls[i], nextPictureUrls[j]] = [nextPictureUrls[j], nextPictureUrls[i]])

      setPictureUrls(nextPictureUrls)
    }
  }

  return (
    <>
      <Box
        mt={2}
        display="flex"
        alignItems="flex-end"
      >
        <Box flexGrow={1}>
          <TextField
            fullWidth
            label="Picture URL"
            type="text"
            margin="normal"
            color="secondary"
            value={inputValue}
            onChange={event => setInputValue(event.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            onSubmit={handleSubmit}
          />
        </Box>
        <Box
          ml={2}
          mb={errorMessage ? 3.7 : 1}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Add
          </Button>
        </Box>
        <Box
          ml={2}
          mb={errorMessage ? 3.7 : 1}
        >
          <Tooltip
            title="Use dummy stock photos for this restaurant. This option should be removed once the client approuves the projet."
            placement="top"
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              disabled={isLoading}
              onClick={handleUseStockPhotos}
            >
              Use stock photos
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Box mt={2}>
        {pictureUrls.map((url, i) => (
          <Box
            key={url + i}
            mb={1}
            className={styles.pictureWrapper}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              top={0}
              bottom={0}
              left={0}
              right={0}
            >
              <Box display="flex">
                {i > 0 && (
                  <IconButton
                    color="inherit"
                    onClick={handleMovePicture(i, -1)}
                  >
                    <ArrowUpwardOutlinedIcon />
                  </IconButton>
                )}
                {i < pictureUrls.length - 1 && (
                  <IconButton
                    color="inherit"
                    onClick={handleMovePicture(i, 1)}
                  >
                    <ArrowDownwardOutlinedIcon />
                  </IconButton>
                )}
                <IconButton
                  color="inherit"
                  onClick={handleDeletePicture(i)}
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
            <img
              src={url}
              className={styles.picture}
              alt="restaurant"
            />
          </Box>
        ))}
      </Box>
    </>
  )
}

export default PictureUrlsInput
