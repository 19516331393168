function trimValues(data) {
  const trimmedData = {}

  Object.entries(data)
  .forEach(([key, value]) => {
    trimmedData[key] = typeof value === 'string' ? value.trim() : value
  })

  return trimmedData
}

export default trimValues
