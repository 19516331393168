import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Helmet } from 'react-helmet'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined'
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined'

import ApplicationLayout from '../../components/ApplicationLayout'
import ProfilePicture from '../../components/ProfilePicture'

import configuration from '../../configuration'
import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import mapError from '../../utils/mapError'
import trimValues from '../../utils/trimValues'

const useStyles = makeStyles({
  profilePictureWrapper: {
    position: 'relative',

    '& > span': {
      opacity: 0,
      transition: 'opacity 0.15s linear',
      cursor: 'pointer',
    },

    '&:hover > span': {
      opacity: 1,
    },
  },
})

function Settings({ viewer }) {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [firstNameError, setFirstNameError] = useState(null)
  const [lastNameError, setLastNameError] = useState(null)
  const history = useHistory()

  const inputLabelProps = { shrink: true }

  const errorSetters = {
    setErrorMessage,
    setEmailError,
    setPasswordError,
    setFirstNameError,
    setLastNameError,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    setIsLoading(true)
    setSuccessMessage(null)

    const data = {}

    if (email && email !== viewer.email) data.email = email
    if (firstName && firstName !== viewer.firstName) data.firstName = firstName
    if (lastName && lastName !== viewer.lastName) data.lastName = lastName
    if (password) data.password = password

    fetchApi('PUT', '/users/viewer', trimValues(data))
    .then(({ data }) => {
      dispatchAction('UPDATE_USER', data)
      dispatchAction('UPDATE_VIEWER', data)

      setEmail('')
      setFirstName('')
      setLastName('')
      setPassword('')
      setSuccessMessage('Your profile has been updated successfully.')
      errorSettersArray.forEach(fn => fn(null))
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      mapError(error.message, errorSetters)
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  function handleDeleteAccountClick() {
    if (!window.confirm('Are you sure you want to delete your account?\nThis action is irreversible.')) return

    setSuccessMessage(null)
    setErrorMessage(null)
    errorSettersArray.forEach(fn => fn(null))

    fetchApi('DELETE', `/users/${viewer.id}`)
    .then(() => {
      localStorage.removeItem(configuration.tokenKey)

      dispatchAction('UNSET_VIEWER')
      dispatchAction('DELETE_USER', { id: viewer.id })

      history.push('/')
    })
    .catch(error => {
      console.log('error', error.message)
      mapError(error.message, errorSetters)
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Settings - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <ApplicationLayout>
        <Container maxWidth="sm">

          <Box
            py={5}
            px={2}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <Box textAlign="center">
              <ProfilePictureWrapper>
                <ProfilePicture
                  url={viewer.profilePictureUrl}
                  size={128}
                />
              </ProfilePictureWrapper>
            </Box>
            <Box
              mt={3}
              textAlign="center"
            >
              <Typography
                variant="h4"
                component="h1"
              >
                Settings
              </Typography>
            </Box>
            {!!errorMessage && (
              <Box mt={1}>
                <Alert
                  fullWidth
                  severity="error"
                >
                  {errorMessage}
                </Alert>
              </Box>
            )}
            {!!successMessage && (
              <Box mt={1}>
                <Alert
                  fullWidth
                  severity="success"
                >
                  {successMessage}
                </Alert>
              </Box>
            )}
            <Box mt={1}>
              <form onSubmit={handleSubmit}>

                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  autoComplete="email"
                  placeholder={viewer.email}
                  margin="normal"
                  InputLabelProps={inputLabelProps}
                  color="secondary"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  error={!!emailError}
                  helperText={emailError}
                />
                <Box display="flex">
                  <Box flexGrow={1}>
                    <TextField
                      fullWidth
                      label="First name"
                      placeholder={viewer.firstName}
                      autoComplete="given-name"
                      margin="normal"
                      InputLabelProps={inputLabelProps}
                      color="secondary"
                      value={firstName}
                      onChange={event => setFirstName(event.target.value)}
                      error={!!firstNameError}
                      helperText={firstNameError}
                    />
                  </Box>
                  <Box
                    flexGrow={1}
                    ml={2}
                  >
                    <TextField
                      fullWidth
                      label="Last name"
                      placeholder={viewer.lastName}
                      autoComplete="family-name"
                      margin="normal"
                      InputLabelProps={inputLabelProps}
                      color="secondary"
                      value={lastName}
                      onChange={event => setLastName(event.target.value)}
                      error={!!lastNameError}
                      helperText={lastNameError}
                    />
                  </Box>
                </Box>
                <TextField
                  fullWidth
                  label="Password (must be at least 8 characters long)"
                  placeholder="••••••••"
                  autoComplete="new-password"
                  margin="normal"
                  InputLabelProps={inputLabelProps}
                  color="secondary"
                  type="password"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  error={!!passwordError}
                  helperText={passwordError}
                />
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isLoading || !(email || firstName || lastName || password)}
                  >
                    Save
                  </Button>
                  <Link
                    color="textSecondary"
                    onClick={handleDeleteAccountClick}
                  >
                    Delete account
                  </Link>
                </Box>

              </form>
            </Box>
          </Box>
        </Container>
      </ApplicationLayout>
    </>
  )
}

function ProfilePictureWrapper({ children }) {
  const [isLoading, setIsLoading] = useState(false)
  const styles = useStyles()

  const icon = isLoading ? (
    <MoreHorizOutlinedIcon />
  ) : (
    <Tooltip
      placement="top"
      title="Generate new profile picture"
    >
      <CachedOutlinedIcon fontSize="large" />
    </Tooltip>
  )
  function handleClick() {
    if (isLoading) return

    setIsLoading(true)

    fetchApi('PUT', '/users/viewer/random-profile-picture')
    .then(({ data }) => {
      const image = new Image()

      image.src = data.profilePictureUrl

      image.onload = () => {
        dispatchAction('UPDATE_USER', data)
        dispatchAction('UPDATE_VIEWER', data)
        setIsLoading(false)
      }
    })
    .catch(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className={styles.profilePictureWrapper}>
      <Box
        component="span"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        borderRadius="50%"
        color="white"
        onClick={handleClick}
      >
        {icon}
      </Box>
      {children}
    </div>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer,
})

export default connect(mapStateToProps)(Settings)
