import React, { useState, useEffect, useRef } from 'react'
import { VictoryChart, VictoryLine, VictoryVoronoiContainer } from 'victory'
import { useTheme } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import RatingCircle from '../../components/RatingCircle'

import roundRating from '../../utils/roundRating'

function averageRatings(review) {
  return (review.qualityOfFoodRating + review.serviceRating + review.settingRating) / 3
}

function RestaurantDashboardAnalytics({ restaurant, reviews }) {
  const rootRef = useRef()
  const [width, setWidth] = useState()
  const theme = useTheme()

  useEffect(() => {
    setWidth(rootRef.current.offsetWidth)
  }, [])

  if (!reviews.length) {
    return (
      <Typography ref={rootRef}>
        This restaurant has no review yet.
      </Typography>
    )
  }

  const workingReviews = reviews.slice().sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)

  const nReviewsOverTime = []
  const averageRatingOverTime = []
  const qualityOfFoodRatingOverTime = []
  const serviceRatingOverTime = []
  const settingRatingOverTime = []

  workingReviews.forEach((review, i) => {
    nReviewsOverTime.push({
      x: new Date(review.createdAt),
      y: i + 1,
    })

    averageRatingOverTime.push({
      x: new Date(review.createdAt),
      y: ((averageRatingOverTime[i - 1] || { y: 0 }).y * averageRatingOverTime.length + averageRatings(review)) / (averageRatingOverTime.length + 1),
    })

    qualityOfFoodRatingOverTime.push({
      x: new Date(review.createdAt),
      y: ((qualityOfFoodRatingOverTime[i - 1] || { y: 0 }).y * qualityOfFoodRatingOverTime.length + review.qualityOfFoodRating) / (qualityOfFoodRatingOverTime.length + 1),
    })

    serviceRatingOverTime.push({
      x: new Date(review.createdAt),
      y: ((serviceRatingOverTime[i - 1] || { y: 0 }).y * serviceRatingOverTime.length + review.serviceRating) / (serviceRatingOverTime.length + 1),
    })

    settingRatingOverTime.push({
      x: new Date(review.createdAt),
      y: ((settingRatingOverTime[i - 1] || { y: 0 }).y * settingRatingOverTime.length + review.settingRating) / (settingRatingOverTime.length + 1),
    })
  })

  const lineStyle = {
    data: {
      stroke: theme.palette.primary[500],
    },
  }

  const domain = { y: [0, 10] }

  console.log('averageRatingOverTime', restaurant)

  return (
    <div ref={rootRef}>
      <Box
        component="section"
        display="flex"
        alignItems="center"
        mt={1}
        mb={2}
      >
        <Box
          mr={4}
          display="flex"
          alignItems="center"
        >
          <Box mr={1}>
            <Typography variant="h6">
              Average rating
            </Typography>
          </Box>
          <RatingCircle rating={restaurant.averageRating} />
        </Box>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
        >
          <Box mr={1}>
            <Typography variant="h6">
              Quality of food rating
            </Typography>
          </Box>
          <RatingCircle rating={restaurant.averageQualityOfFoodRating} />
        </Box>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
        >
          <Box mr={1}>
            <Typography variant="h6">
              Service rating
            </Typography>
          </Box>
          <RatingCircle rating={restaurant.averageServiceRating} />
        </Box>
        <Box
          mr={4}
          display="flex"
          alignItems="center"
        >
          <Box mr={1}>
            <Typography variant="h6">
              Setting rating
            </Typography>
          </Box>
          <RatingCircle rating={restaurant.averageSettingRating} />
        </Box>
      </Box>
      <section>
        <Typography
          variant="h5"
          component="h2"
        >
          Reviews over time
        </Typography>
        <VictoryChart
          width={width}
          height={280}
          scale={{ x: 'time' }}
          containerComponent={(
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x.toLocaleString()}: ${datum.y}`}
            />
          )}
        >
          <VictoryLine
            data={nReviewsOverTime}
            style={lineStyle}
          />
        </VictoryChart>
      </section>
      <section>
        <Typography
          variant="h5"
          component="h2"
        >
          Average rating over time
        </Typography>
        <VictoryChart
          width={width}
          height={280}
          scale={{ x: 'time' }}
          domain={domain}
          containerComponent={(
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x.toLocaleString()}: ${roundRating(datum.y)}`}
            />
          )}
        >
          <VictoryLine
            data={averageRatingOverTime}
            style={lineStyle}
          />
        </VictoryChart>
      </section>
      <section>
        <Typography
          variant="h5"
          component="h2"
        >
          Quality of food rating over time
        </Typography>
        <VictoryChart
          width={width}
          height={280}
          scale={{ x: 'time' }}
          domain={domain}
        >
          <VictoryLine
            data={qualityOfFoodRatingOverTime}
            style={lineStyle}
          />
        </VictoryChart>
      </section>
      <section>
        <Typography
          variant="h5"
          component="h2"
        >
          Service rating over time
        </Typography>
        <VictoryChart
          width={width}
          height={280}
          scale={{ x: 'time' }}
          domain={domain}
          containerComponent={(
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x.toLocaleString()}: ${roundRating(datum.y)}`}
            />
          )}
        >
          <VictoryLine
            data={serviceRatingOverTime}
            style={lineStyle}
          />
        </VictoryChart>
      </section>
      <section>
        <Typography
          variant="h5"
          component="h2"
        >
          Setting rating over time
        </Typography>
        <VictoryChart
          width={width}
          height={280}
          scale={{ x: 'time' }}
          domain={domain}
          containerComponent={(
            <VictoryVoronoiContainer
              labels={({ datum }) => `${datum.x.toLocaleString()}: ${roundRating(datum.y)}`}
            />
          )}
        >
          <VictoryLine
            data={settingRatingOverTime}
            style={lineStyle}
          />
        </VictoryChart>
      </section>
    </div>
  )
}

export default RestaurantDashboardAnalytics
