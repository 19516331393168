import React from 'react'

import AdminisitratorDashboardPage from './AdministratorDashboardPage'

function AdministratorDashboardUsers() {
  return (
    <AdminisitratorDashboardPage
      actionTypeSuffix="USER"
      fetchUrl="/users"
      stateKey="users"
      columns={{
        email: {
          type: 'string',
        },
        firstName: {
          type: 'string',
        },
        lastName: {
          type: 'string',
        },
        profilePictureUrl: {
          type: 'string',
        },
        isAdministrator: {
          type: 'boolean',
        },
      }}
    />
  )
}

export default AdministratorDashboardUsers
