function mapError(errorMessage, {
  setErrorMessage,
  setEmailError,
  setPasswordError,
  setFirstNameError,
  setLastNameError,
  setNameError,
  setDescriptionError,
  setAddressError,
  setPhoneNumberError,
  setAveragePriceError,
  setCommentError,
  setReplyError,
}) {
  const mapping = {
    '"email" is not allowed to be empty': () => setEmailError('Email must not be empty.'),
    '"email" must be a valid email': () => setEmailError('You must enter a valid email address.'),
    'User with given email was not found.': () => setEmailError('We could not find an account with this email.'),
    '"password" is not allowed to be empty': () => setPasswordError('Password must not be empty.'),
    '"password" length must be at least 8 characters long': () => setPasswordError('Your password must be at leat 8 characters long.'),
    'Wrong password.': () => setPasswordError('Wrong password.'),
    '"firstName" is not allowed to be empty': () => setFirstNameError('First name must not be empty.'),
    '"lastName" is not allowed to be empty': () => setLastNameError('Last name must not be empty.'),
    'An account with this email already exists.': () => setEmailError('An account with this email already exists.'),
    '"name" is not allowed to be empty': () => setNameError('Name must not be empty.'),
    '"description" is not allowed to be empty': () => setDescriptionError('Description must not be empty.'),
    '"address" is not allowed to be empty': () => setAddressError('Address must not be empty.'),
    '"phoneNumber" is not allowed to be empty': () => setPhoneNumberError('Phone number must not be empty.'),
    '"averagePrice" must be a number': () => setAveragePriceError('Average price must not be empty.'),
    '"comment" is not allowed to be empty': () => setCommentError('Comment must not be empty.'),
    '"reply" is not allowed to be empty': () => setReplyError('Reply must not be empty.'),
    defaultOption: () => setErrorMessage('An error occured.'),
  }

  return (mapping[errorMessage] || mapping.defaultOption)()
}

export default mapError
