import { useEffect } from 'react'
import { connect } from 'react-redux'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'

import configuration from '../configuration'

function ViewerFetcher({ children, viewer }) {
  useEffect(() => {
    if (viewer) return

    fetchApi('GET', '/users/viewer')
    .then(({ data }) => {
      dispatchAction('SET_VIEWER', data)
    })
    .catch(error => {
      console.log('error', error)

      localStorage.removeItem(configuration.tokenKey)

      dispatchAction('UNSET_VIEWER')

      if (viewer && viewer.id) {
        dispatchAction('DELETE_USER', { id: viewer.id })
      }
    })
    .finally(() => {
      dispatchAction('SET_BOOLEAN', { isViewerLoaded: true })
    })
  }, [])

  return children
}

const mapStateToProps = s => ({
  viewer: s.viewer,
})

export default connect(mapStateToProps)(ViewerFetcher)
