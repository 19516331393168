import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'

import Review from '../../components/Review'
import ReviewReplyModal from '../../components/ReviewReplyModal'

const useStyles = makeStyles({
  threshold: {
    minWidth: 48,
    textAlign: 'right',
  },
})

function averageRatings(review) {
  return (review.qualityOfFoodRating + review.serviceRating + review.settingRating) / 3
}

function RestaurantDashboardReviews({ reviews, users }) {
  const [threshold, setThreshold] = useState(5)
  const styles = useStyles()

  if (!reviews.length) {
    return (
      <Typography>
        This restaurant has no review yet.
      </Typography>
    )
  }

  const sortedReviews = reviews.slice().sort((a, b) => averageRatings(a) > averageRatings(b) ? -1 : 1)

  const goodReviews = []
  const badReviews = []
  const newReviews = []

  sortedReviews.forEach(review => {
    if (averageRatings(review) >= threshold) {
      goodReviews.push(review)
    }
    else {
      badReviews.unshift(review)
    }

    if (!review.reply) {
      newReviews.push(review)
    }
  })

  newReviews.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)

  return (
    <>
      <Box display="flex">
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
          textAlign="center"
        >
          <Typography
            variant="h5"
            component="h2"
          >
            New reviews ({newReviews.length})
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
          textAlign="center"
        >
          <Typography
            variant="h5"
            component="h2"
          >
            Bad reviews ({badReviews.length})
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
          textAlign="center"
        >
          <Typography
            variant="h5"
            component="h2"
          >
            Good reviews ({goodReviews.length})
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        mt={0.5}
      >
        <Box
          flexBasis="33.33%"
          flexGrow={1}
          mx={2}
        />
        <Box
          flexGrow={2}
          flexBasis="66.66%"
          mr={2}
          display="flex"
          alignItems="center"
        >
          <Typography>
            Rating threshold:
          </Typography>
          <Box
            mx={2}
            flexGrow={1}
          >
            <Slider
              value={threshold}
              onChange={(event, value) => setThreshold(value)}
              min={0}
              max={10}
              step={0.5}
            />
          </Box>
          <Typography className={styles.threshold}>
            {threshold}/10
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
      >
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
        >
          <ReviewList
            reviews={newReviews}
            users={users}
          />
        </Box>
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
        >
          <ReviewList
            reviews={badReviews}
            users={users}
          />
        </Box>
        <Box
          flexGrow={1}
          flexBasis="33.33%"
          mx={2}
        >
          <ReviewList
            reviews={goodReviews}
            users={users}
          />
        </Box>
      </Box>
    </>
  )
}

function ReviewList({ reviews, users }) {
  const [repliedReview, setRepliedReview] = useState(null)

  return (
    <>
      {reviews.map(review => (
        <Box
          key={review.id}
          mt={4}
        >
          <Review
            detailedRatings
            noDeleteOption
            review={review}
            label={`${users[review.userId].firstName} ${users[review.userId].lastName}`}
            pictureUrl={users[review.userId].profilePictureUrl}
            linkTo={`/user/${review.userId}`}
          />
          {!review.reply && (
            <Box mt={1}>
              <Link onClick={() => setRepliedReview(review)}>
                Reply
              </Link>
            </Box>
          )}
        </Box>
      ))}
      {!!repliedReview && (
        <ReviewReplyModal
          review={repliedReview}
          user={users[repliedReview.userId]}
          onClose={() => setRepliedReview(null)}
        />
      )}
    </>
  )
}

export default RestaurantDashboardReviews
