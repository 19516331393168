/*
  The sign-in user from the database
*/

const initialState = null

function viewer(state = initialState, action) {

  if (action.type === 'SET_VIEWER') {
    return action.payload
  }

  if (action.type === 'UNSET_VIEWER') {
    return initialState
  }

  if (action.type === 'UPDATE_VIEWER') {
    return {
      ...state,
      ...action.payload,
    }
  }

  return state
}

export default viewer
