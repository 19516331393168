import React from 'react'

import AdminisitratorDashboardPage from './AdministratorDashboardPage'

import tags from '../../data/tags.json'

function AdministratorDashboardRestaurants() {
  return (
    <AdminisitratorDashboardPage
      actionTypeSuffix="RESTAURANT"
      fetchUrl="/restaurants"
      stateKey="restaurants"
      columns={{
        name: {
          type: 'string',
        },
        description: {
          type: 'string',
        },
        address: {
          type: 'string',
        },
        phoneNumber: {
          type: 'string',
        },
        averagePrice: {
          type: 'number',
        },
        tags: {
          type: 'string[]',
          possibleValues: tags,
        },
        pictureUrls: {
          type: 'string[]',
        },
        userId: {
          type: 'number',
          doesNotUpdate: true,
        },
      }}
    />
  )
}

export default AdministratorDashboardRestaurants
