import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import AuthenticationLayout from '../../components/AuthenticationLayout'

import fetchApi from '../../fetchApi'
import dispatchAction from '../../state/dispatchAction'
import mapError from '../../utils/mapError'
import trimValues from '../../utils/trimValues'

function SignInScene({ viewer }) {
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const history = useHistory()

  const inputLabelProps = { shrink: true }

  const errorSetters = {
    setErrorMessage,
    setEmailError,
    setPasswordError,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    setIsLoading(true)

    fetchApi('POST', '/users/sign-in', trimValues({
      email,
      password,
    }))
    .then(({ data }) => {
      dispatchAction('SET_VIEWER', data)
      history.push('/restaurants')
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      setIsLoading(false)
      mapError(error.message, errorSetters)
    })
  }

  return (
    <>
      <Helmet>
        <title>
          Sign in - Gourmet: eat, review, love.
        </title>
      </Helmet>

      <AuthenticationLayout title="Sign in">
        {!!viewer && (
          <Alert severity="info">
            You are already signed in as {viewer.firstName} {viewer.lastName}
          </Alert>
        )}
        {!!errorMessage && (
          <Box mt={viewer ? 1 : 0}>
            <Alert severity="error">
              {errorMessage}
            </Alert>
          </Box>
        )}
        <form onSubmit={handleSubmit}>

          <TextField
            fullWidth
            label="Email"
            type="email"
            autoComplete="email"
            placeholder="alain.ducasse@chef.com"
            margin="normal"
            InputLabelProps={inputLabelProps}
            color="secondary"
            value={email}
            onChange={event => setEmail(event.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            fullWidth
            label="Password"
            placeholder="••••••••"
            autoComplete="password"
            margin="normal"
            InputLabelProps={inputLabelProps}
            color="secondary"
            type="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />

          <Box
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Continue
            </Button>
            <Box ml={2}>
              <Typography>
                New on Gourmet? <Link to="/sign-up" component={RouterLink}>Sign up</Link>.
              </Typography>
            </Box>
          </Box>

        </form>
      </AuthenticationLayout>
    </>
  )
}

const mapStateToProps = s => ({
  viewer: s.viewer,
})

export default connect(mapStateToProps)(SignInScene)
