import configuration from './configuration'

function fetchApi(method, route, payload) {
  const token = localStorage.getItem(configuration.tokenKey)
  const options = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  if (token) {
    options.headers.Authorization = `Bearer ${token}`
  }

  if (payload) {
    options.body = JSON.stringify(payload)
  }

  return fetch(configuration.apiHost + route, options)
  .then(async response => {
    const authorizationHeader = response.headers.get('authorization')

    if (authorizationHeader) {
      const token = authorizationHeader.split('Bearer ')[1]

      localStorage.setItem(configuration.tokenKey, token)
    }

    const data = await response.json()

    if (response.ok) {
      return data
    }

    throw data
  })
}

export default fetchApi
