import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import Review from './Review'

import fetchApi from '../fetchApi'
import dispatchAction from '../state/dispatchAction'
import mapError from '../utils/mapError'
import trimValues from '../utils/trimValues'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(4),
    width: 512,

    '&:focus': {
      outline: 'none',
    },
  },
}))

function ReviewReplyModal({ review, user, onClose }) {
  const [isLoading, setIsLoading] = useState(false)
  const [reply, setReply] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)
  const [replyError, setReplyError] = useState(null)
  const styles = useStyles()

  const userFullName = `${user.firstName} ${user.lastName}`

  const errorSetters = {
    setReplyError,
    setErrorMessage,
  }

  const errorSettersArray = Object.values(errorSetters)

  function handleSubmit(event) {
    event.preventDefault()

    if (isLoading) return

    setIsLoading(true)

    fetchApi('PUT', `/reviews/${review.id}/reply`, trimValues({
      reply,
    }))
    .then(({ data }) => {
      dispatchAction('UPDATE_REVIEW', data)
      onClose()
    })
    .catch(error => {
      console.log('error', error.message)
      errorSettersArray.forEach(fn => fn(null))
      setIsLoading(false)
      mapError(error.message, errorSetters)
    })
  }

  return (
    <Modal
      open
      onClose={onClose}
      className={styles.modal}
    >
      <Paper className={styles.paper}>

        <Typography
          variant="h4"
          component="h1"
        >
          Reply to {userFullName}'s review
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          mt={2}
        >
          {!!errorMessage && (
            <Box mb={1}>
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Box>
          )}
          <Review
            detailedRatings
            review={review}
            label={userFullName}
            pictureUrl={user.profilePictureUrl}
            linkTo={`/user/${user.id}`}
          />
          <TextField
            fullWidth
            multiline
            rows={6}
            label="Reply"
            type="text"
            margin="normal"
            color="secondary"
            value={reply}
            onChange={event => setReply(event.target.value)}
            error={!!replyError}
            helperText={replyError}
          />
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={handleSubmit}
              disabled={isLoading || !reply.trim()}
            >
              Reply to review
            </Button>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

export default ReviewReplyModal
